import React, { useEffect } from 'react'

import 'react-phone-input-2/lib/style.css'
import './App.css';

import { getUtalityLookups } from "./redux/features/lookups";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import AuthStack from './AuthStack'
import mixpanel from 'mixpanel-browser';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getTranslationHandler } from './redux/features/language';

import useTokens from "../src/Hooks/useTokens"
import { LANGUAGE_TYPE } from './Helper/constants';
import TagManager from 'react-gtm-module'

mixpanel.init(process.env.REACT_APP_MIXPANEL, {debug: process.env.REACT_APP_IS_LOCAL,cross_site_cookie: true}); 

window.mixpanel = mixpanel

function App() {
  
  const dispatch = useDispatch()
  const {lang} = useSelector(state => state.language);
  
  useEffect(() => {
    // mixpanel.track("landing page", {
    //   "language": lang
    // });
    dispatch(getUtalityLookups())
    // install(process.env.REACT_APP_GTAG_G,{ 'send_page_view': true });
    // gtag('config', process.env.REACT_APP_GTAG_G);
    // if(process.env.REACT_APP_GTAG_AW){
    //   gtag('config', process.env.REACT_APP_GTAG_AW);
    // }
    if(process.env.REACT_APP_GTAG_G){
      const tagManagerArgs = {
        gtmId: process.env.REACT_APP_GTAG_G
      }
      TagManager.initialize(tagManagerArgs)
    }
  
  }, [])

  useEffect(() => {
    if(window?.groove){
      if(lang === LANGUAGE_TYPE.ar){
        window.groove.widget.init(process.env.REACT_APP_GROOVE_AR);
      }else{
        window.groove.widget.init(process.env.REACT_APP_GROOVE);
      }
    }

  }, [lang])


  useEffect(() => { getTranslation() }, [lang])
  /**
   * getTranslations
   */
  const getTranslation = ()=>{
    dispatch(getTranslationHandler({
      category: 'common',
      lang: lang
    }))
  }
  
  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={
          <CheckAuth>
            <AuthStack/>
          </CheckAuth>
        }
        />
      </Routes>
      <ToastContainer 
        autoClose={2000}
        position={lang === LANGUAGE_TYPE.ar? 'top-left' :'top-right'}
      />
    </BrowserRouter>
  );
}


function CheckAuth({ children }){
  
  const {accessToken,subdomain, removeCookies} = useTokens()
   
  // const accessToken = cookies?.accessToken || false

  if (accessToken && accessToken != "undefined" && subdomain && subdomain != "undefined") {
    
  // if (false) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    // return <Navigate to="/login" state={{ from: location }} replace />;
    // return <Navigate to="/dashboard" replace />
    window.location.replace(`https://${subdomain}.${process.env.REACT_APP_DOMAIN}/dashboard`)
    return children
  }

  return children;
}

export default App;

