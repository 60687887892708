import React from 'react'
import ReactLoading from 'react-loading';
function AppButton({children,disabled = false,haswhite = false,className = "primary", onClick, title, isLoading = false,style={}}) {
    const loadingColor = className === "white" || haswhite?"#020230": "white"
    return (
        <button 
            className = {`button ${className}`} 
            onClick = {onClick}
            disabled = {isLoading || disabled}
            style = {style}
        >
            {
                isLoading?
                <div>
                    <ReactLoading type={"cylon"} color={loadingColor} height = {30} width = {"100%"}/>
                </div>
                :
                <>
                    <div>{title}</div>
                    {
                        (children) && title?
                        <div style={{width: 7}}></div>
                        :
                        <></>
                    }
                    {children}
                </>
            }
        </button>
        
    )
}

export default AppButton