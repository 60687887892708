import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import {useNavigate} from 'react-router-dom'
import { angleBottom, business, businessServiceSvg, chartSvg, deals, ecommerceSvg, financeSvg, industrySvg, integrationsSvg, mediaSvg, saasSvg, systems, transportSvg, users } from "../assets/svgs";
import { LANGUAGE_TYPE } from "../Helper/constants";
import mixpanel from 'mixpanel-browser';
import { getCookie, setCookies } from "../Helper/functions";
import { useDispatch, useSelector } from "react-redux";
import { changeLanguage, getTranslationHandler } from "../redux/features/language";
import AppButton from "../Components/AppButton";
import useTokens from "../Hooks/useTokens";
// import { landingPageTranslations } from "../../translation";

export default function Header({toRoute,onLangChange}) {

  const dispatch = useDispatch()
  const navigate = useNavigate();  
  const {accessToken,setAccessToken,setDomainName} = useTokens();

  const [menu, setMenu] = useState(false);
  const {status, page, translations, lang} = useSelector(state => state.language);
  const landingPageTranslations = translations?.landingPage || {};
  const [langDropdown, setLangDropdown] = useState(lang == LANGUAGE_TYPE.en? "en": "العربية");
  const availableLangs = ["en", "العربية"];
  
  
  
  useEffect(() => {
    // setAccessToken("TEST",`.${process.env.REACT_APP_DOMAIN}`)
    // setDomainName("TESTasdasdasdas1",`.${process.env.REACT_APP_DOMAIN}`)
    // setTimeout(() => {
      
    // }, 10000);
    if (langDropdown === "العربية") {
      onLangChange("ar")
      setCookies("language",LANGUAGE_TYPE.ar,10000,`.${process.env.REACT_APP_DOMAIN}`)
      dispatch(changeLanguage(LANGUAGE_TYPE.ar))
      document.body.classList.add("arabic")
    } else {
      onLangChange("en")
      dispatch(changeLanguage(LANGUAGE_TYPE.en))
      setCookies("language",LANGUAGE_TYPE.en,10000,`.${process.env.REACT_APP_DOMAIN}`)
      document.body.classList.remove("arabic")
    }

  }, [langDropdown])
  
  
  const menuClose = React.useCallback((e) => {
    
    const target = e.target;
    if (target === document.querySelector(".nav")) {
      document.body.classList.remove("active")
      document.body.removeEventListener("click", menuClose)
      setMenu(false)
    }

  }, [])

  useEffect(() => {
    if (menu) {
      document.body.addEventListener("click", menuClose)
      document.body.classList.add("active")
    }
  }, [menu])

  /**
   * Get Translation from server
   */
  useEffect(() => getTranslation(), [lang])
  const getTranslation = ()=>{
    dispatch(getTranslationHandler({
      category: 'landingPage',
      lang: lang
    }))
  }


  // const changeLangue = (_lang)=>{
  //   dispatch(getTranslationHandler({lang:_lang, category: page}))
  // }

  const links  = document.querySelectorAll(".nav__inner-link")
  const button = document.querySelectorAll(".button")

  links.forEach((e) => {onLinkClick(e)})
  button.forEach((e) => { onButtonClick(e)})

  function onButtonClick(linkItem) {
    linkItem.addEventListener("click", function () {
      setMenu(false)
      document.body.classList.remove("active")
    })
  }

  function onLinkClick(linkItem) {
    linkItem.addEventListener("click", function () {
      setMenu(false)
      document.body.classList.remove("active")
    })
  }

  
  
  return (
    <header className="header" id="header">
      <div className="auto__container">
        <div className="header__inner">
          <div onClick={()=>{window.location.replace(process.env.REACT_APP_REDIRECT_URL + toRoute)}} to="/" className="header__inner-logo">
            <img src={process.env.PUBLIC_URL + "/images/logoLight.svg"} alt="logo" />
          </div>
          
          <nav className={"nav " + (menu ? "active" : "")} id="menu">
            <div className="nav__inner">
             
            </div>
            <div className="nav__inner">
              <Link to={`${toRoute}/login`} className="button">{landingPageTranslations["landingPage.login"] || "Login"}</Link>
              {/* <AppButton
                title={landingPageTranslations["landingPage.login"] || "Login"}
                onClick={()=>navigate(`${process.env.REACT_APP_REDIRECT_URL}/login`)}
              /> */}
              <AppButton 
                title={landingPageTranslations["landingPage.free_trial"] || "Free Trial"}
                className="button skyBlue"
                onClick = {()=>{
                  mixpanel.track("free trial clicked", {
                    "language": "en_US",
                    "section": "navigation bar",
                  });
                  navigate(`${toRoute}/signup`,{state: {type: "simple",init: true},replace: true,})
                }}
              />
                
              <div className="nav__inner-lang">
                  <div className="nav__inner-lang-title">
                    <img src={process.env.PUBLIC_URL + "/images/icons/global.svg"} alt="global" />
                    {langDropdown}
                    {/* <span></span> */}
                  </div>
                  
                  <div className="nav__inner-lang-content">
                    {availableLangs.map((item, index) => {
                      return item !== langDropdown ? (
                        <div
                          key={index}
                          className="nav__inner-lang-content-link"
                          onClick={() => setLangDropdown(item)}
                        >
                          {item}
                        </div>
                      ) : (
                        <div key={index}></div>
                      );
                    })}
                  </div>

              </div>
            </div>
          </nav>


          
          <div
            className={"burger " + (menu ? "active" : "")}
            id="menuBtn"
            onClick={() => {
              setMenu(!menu);
            }}
          ></div>

        </div>
      </div>
    </header>
  );
}
