
export function setCookies(name,value,days, domain = false) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    if(domain){
        document.cookie = name + "=" + (value || "")+ ";domain=" + domain + expires + "; path=/";
    }else{
        document.cookie = name + "=" + (value || "") + expires + "; path=/";

    }
    return getCookie(name)
}

export function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}

export function removeCookie(name, path = "/") {
    
    if(getCookie(name)) {
        document.cookie = name + "=" +
        ";path="+path+
        ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
        return true
    }else{
        // alert("Not found")
        return false
    }
      

    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}


export const checkPlusIsExists = (mobile = "") => mobile.includes("+")? mobile :`+${mobile}`