
import { configureStore } from '@reduxjs/toolkit'
import languageReducer from './features/language'
import lookupsReducer from './features/lookups'

export const store = configureStore({
  reducer: {
    language: languageReducer,
    lookups: lookupsReducer,
  },
})