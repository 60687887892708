import React, { useRef, useState } from "react";
import { attentSvg } from "../../../assets/svgs";

import ReactLoading from 'react-loading';
import ReCAPTCHA from "react-google-recaptcha";
// import { GoogleLogin } from '@react-oauth/google';
// import { checkPasswordStrengthApi } from "../../../apis";
import PasswordStrengthBar from 'react-password-strength-bar';
import { useDispatch, useSelector } from "react-redux";
import AppInput from "../../../Components/AppInput";
import AppPhoneInput from "../../../Components/AppPhoneInput";
import queryString from 'query-string'
import { Outlet, useSearchParams } from "react-router-dom";
import AppButton from "../../../Components/AppButton";
import { checkPasswordStrengthApi } from "../../../apis";
import { statusCode } from "../../../Helper/constants";
// import { passwordStrengthHandler } from "../../../redux/features/auth/authSlice";

export default function Step1({setErrors,errors = {},goBackHandler, plansExtra= {},plans = {}, translation = {},state = {}, submitForm, setState, reCaptchaRef, isLoading }) {

  // const {} = useSelector(state => state?.language);
  const {lang,translations} = useSelector(state => state?.language);
  const commonTranslation = translations?.common || {};
  const parsed = queryString.parse(window.location.search);
  const [searchParams, setSearchParams]  = useSearchParams();

  const handleChange = async(key, value)=>{
    const _errors = errors
    if(_errors[key]){
      delete _errors[key]
      setErrors(errors)
    }
    setState((state)=>({
      ...state,
      [key]: value
    }))
    if(key === "password"){
      let res = await checkPasswordStrengthApi(value,lang)
      res = JSON.parse(res)
      if(res.code === statusCode.K422){
        setErrors({...errors, password: res?.errors?.password || []})
      }else if(res.code === statusCode.K200){
        setErrors({...errors, password: []})

      }
      // dispatch(passwordStrengthHandler({value,lang}))
    }
  }


  return (
    <>
      {/* add active to show */}
      <div className={`error ${state?.socialError?"active": ""}`}>
        <div className="error__icon">{attentSvg}</div>
        <div className="error__text">{state?.socialError}</div>
      </div>

      <div>
            <div>
              <div className="signInput__row">
                <div className="signInput__col">
                  <AppInput
                    label = {commonTranslation["common.first_name"] || "First Name"}
                    error = {errors?.first_name? errors?.first_name: null}
                    type="text"
                    placeholder={commonTranslation["common.first_name"]}
                    id="fname"
                    name="first_name"
                    value={state?.first_name}
                    onChange = {({target})=>handleChange("first_name",target.value)}
                  />
                </div>
                <div className="signInput__col">
                  <AppInput
                    label       = {commonTranslation["common.last_name"] || "Last Name"}
                    placeholder = {commonTranslation["common.last_name"]}
                    type="text"
                    id="last_name"
                    name="last_name"
                    value={state?.last_name}
                    onChange = {({target})=>handleChange("last_name",target.value)}
                    error = {errors?.last_name?errors?.last_name: null}
                  />
                </div>
              </div>
              {/* <div className="signInput">
                <label htmlFor="">Username</label>
                <div className="signInput__inner">
                  <input placeholder="John" type="text" id="username" name="username" value={values.username} onChange = {handleChange}/>
                </div>
              </div> */}
                  <AppInput
                    label = {commonTranslation["common.email_address"] || "Email Address"}
                    type="text"
                    id="email"
                    name="email"
                    onChange = {({target})=>handleChange("email",target.value)}
                    value = {state?.email}
                    error = {errors?.email? errors?.email: null}
                  />
                  <AppPhoneInput
                    name = {"mobile"}
                    value = {state?.mobile}
                    onChange = {(e)=>handleChange("mobile",e)}
                    // onChange = {(number,country,e)=>{
                    //   handleChange(e);
                    // }}
                    label = {commonTranslation["common.mobile_number"] || "Mobile Number"}
                    error = {errors?.mobile? errors?.mobile : null}
                  />

              {/* <div className="signSelect">
                <label htmlFor="">{translation["signupPage.mobile_number"]}</label>
                <div className="signInput__inner">
                  <div className="signSelect__select">
                    <select
                      value={state?.code}
                      onChange={e => setState({...state,code: e.target.value})}
                    >
                      {countriesCode.map(cc=>(<option key={cc.code} value={cc.dial_code}>{cc.flag}{cc.dial_code}</option>))}

                    </select>
                  </div>
                  <input placeholder={translation["signupPage.mobile_number"]} type="text" id="mobile" name="mobile" value={values.mobile} onChange = {handleChange} style={{borderTopLeftRadius: 0, borderBottomLeftRadius: 0,lineHeight: "normal"}}/>
                </div>
                {errors?.mobile? <span className = {"error__text"}>{errors?.mobile}</span> : null}
              </div> */}
              <AppInput
                label = {translation["signupPage.company_name"] || "Company Name"}
                placeholder={translation["signupPage.company_name"]}
                type="text"
                id="company_name"
                name="company_name"
                value={state?.company_name}
                onChange = {({target})=>handleChange("company_name",target.value)}
                error = {errors?.company_name ? errors?.company_name : null}
              />
              {/* <div className="signInput">
                <label htmlFor="">{translation["signupPage.company_name"]}</label>
                <div className="signInput__inner">
                  <input />
                </div>

              </div> */}
              {/* <div className="signInput">
                <label htmlFor="">Time Zone</label>
                <div className="signInput__inner">
                  <input placeholder = {"Amaan"} type="text" id="timezone" name="timezone" value={values.timezone} onChange = {handleChange}/>
                </div>
                  {errors?.timezone? <span className = {"error__text"}>{errors?.timezone}</span> : null}
              </div> */}

              {/* <div className="signInput">
                <label htmlFor="">{commonTranslation["common.password"]}</label>
                <div className="signInput__inner">
                  <div>
                    <Field
                      type={"password"}
                      placeholder={commonTranslation["common.password"]}
                      validate = {checkPasswordStrengthHander} name="password"
                    />
                  </div>
                </div>
              </div> */}

            <AppInput
              label = {commonTranslation["common.password"] || "Password"}
              placeholder={commonTranslation["common.password"]}
              type="password"
              id="password"
              name="password"
              value={state?.password}
              onChange = {({target})=>handleChange("password",target.value)}
              // onChange = {async(e)=>{
              //   handleChange(e)
              //   const res = await checkPasswordStrengthHander(e.target.value)
              //   if(res) setErrors({...errors,password:res})
              // }}
              // error = {errors?.password ? errors?.password: null}
            />

              <AppInput
                label = {commonTranslation["common.confirm_password"] || "Confirm Password"}
                placeholder={commonTranslation["common.confirm_password"]}
                id="password_confirm"
                name="password_confirm"
                value={state?.password_confirm}
                onChange = {({target})=>handleChange("password_confirm",target.value)}
                type="password"
                error = {errors?.password_confirm? errors?.password_confirm : null}
              />

              {/* <div className="signInput">
                <label htmlFor="">{commonTranslation["common.confirm_password"]}</label>
                <div className="signInput__inner">
                  <div>
                    <input type={"password"} placeholder={commonTranslation["common.confirm_password"]} id="password_confirm" name="password_confirm" value={values.password_confirm} onChange = {handleChange}/>
                  </div>
                  {errors?.password_confirm ? <div className = {"error__text"}>{errors?.password_confirm}</div> : null}
                </div>
              </div> */}


              <div className="signInput">
                  <div style={{marginTop: 15, marginBottom: 5, display: 'flex'}}>
                    <label style={{marginBottom: 0, marginInlineEnd: 10}}>{commonTranslation["common.password_strength"] || "Password Strength"}</label>
                    <div style={{flex: 1}}>
                      <PasswordStrengthBar password={state?.password} scoreWords = {[]} shortScoreWord = {""}/>
                    </div>
                  </div>
                  {
                    errors?.password && state?.password?.length > 0?
                      <>
                        {
                          errors?.password.map((p,index)=>(
                            <p style={{margin: "5px 0"}} key={index} className = {"error__text"}>{p}</p>
                          ))
                        }
                      </>
                      :
                      <label style={{marginTop: 10}}>{commonTranslation["common.hint_password"] || "- Add 8 characters or more, lowercase letters, uppercase letters, numbers and symbols to make the password strong"}</label>

                  }
              </div>
            </div>
              {
                Object.keys(plans || {}).length > 0?
                <>
                <div className="signInput">
                  <label style={{marginBottom: 0, marginRight: 10}}>{`${commonTranslation["common.subscription_plan"]  || "Subscription Plan"} (${commonTranslation["common.trail_for"] || "Trial for"} ${plansExtra?.trial_period || 0} ${commonTranslation["common.days"] || "days"})`}</label>
                </div>
                <div className="row" style={{alignItems: "normal"}}>
                  {
                    Object.keys(plans || {}).map(planKey=>{
                      const plan = plans[planKey]
                      return(
                        <div class={`plan_card__header ${planKey === parsed?.id? "active": ""}`} onClick = {()=>{
                          setSearchParams({id: planKey})
                        }}>
                          <div class="row">
                            <div class="plan_card_title">{plan.title}</div>
                            <div class="plan_card_number"><strong>{plan.currency}{plan.price}</strong>{commonTranslation["common.user"] || "user"}/{plan.per}</div>
                          </div>
                          {
                            plan?.most_popular?
                            <span class="most-popular-plan-container">
                              <span>🔥</span>
                              <span class="most-popular-plan">{commonTranslation["common.most_popular"] || "Most popular"}</span>
                            </span>
                            :<></>
                          }
                        </div>
                      )
                    })
                  }
                </div>
                </>
                :
                <></>
              }
              {
                errors?.plan_id?
                <div className="signInput">
                  {errors?.plan_id? <span className = {"error__text"}>{errors?.plan_id}</span> : null}
                </div>
                :<></>
              }

            <div className="signCheck" style={{marginTop: 10}}>
              <ReCAPTCHA
                ref = {reCaptchaRef}
                sitekey={process.env.REACT_APP_RECAPTCHA_CLIENT_ID}
                // onChange={(value)=>setState({...state,reCaptcha: value})}
                size = {"invisible"}
                onError = {(e)=>{console.log(e)}}
              />
              {errors?.reCaptcha? <span className = {"error__text"}>{errors?.reCaptcha}</span> : null}
            </div>
            {
              errors?.message?
              <div className="signInput">
                {errors?.message ? <span className = {"error__text"}>{errors?.message}</span> : null}
              </div>
              :<></>
            }
            <AppButton
              title={commonTranslation["common.get_started"] || "Proceed"}
              onClick = {()=>{
                submitForm()
              }}
              isLoading = {isLoading}
              style = {{width: "100%", marginBottom: 10}}
            />
            <AppButton
              className="white"
              title={commonTranslation["common.back"] || "Back"}
              onClick = {goBackHandler}
              style = {{width: "100%"}}
            />
          </div>
    </>
  );
}
