import React, { useEffect, useState } from "react";
// import { checkPasswordStrengthApi, linkedInAccessToken, loginSocialSignupApi } from "../../apis";

// import ReactLoading from 'react-loading';
import { useDispatch, useSelector } from "react-redux";
import {  getTranslationHandler } from "../../redux/features/language";
import { useNavigate } from "react-router";
import { useLinkedIn } from 'react-linkedin-login-oauth2';
// import { login, reset, socialLogin } from "../../redux/features/auth/authSlice";

import { Link } from "react-router-dom";
import { toast } from 'react-toastify';

import AppInput from '../../Components/AppInput';
// import { MyRefreshTokenVar, statusCode } from "../../Helper/constants";
// import useTokens from "../../Hooks/useTokens";
// import mixpanel from 'mixpanel-browser';
import { GoogleLogin } from '@react-oauth/google';
import AppButton from "../../Components/AppButton";
import { loginApi, loginSocialSignupApi } from "../../apis";
import { mixpanelActions, MyAccessTokenVar, MyRefreshTokenVar, statusCode, SubDomain } from "../../Helper/constants";
import { setCookies } from "../../Helper/functions";
import mixpanel from "mixpanel-browser";





const Login = ({toRoute})=>{
  
  const {lang, translations} = useSelector(state => state.language);
  // const {isError, user, isSuccess, errors, step, code, token} = useSelector(state => state.auth)
  const dispatch  = useDispatch();
  const navigate  = useNavigate();
  // const {setAccessToken, setRefreshToken,setTokens} = useTokens()
  
  const [isRememberme, setIsRememberme] = useState(localStorage.getItem("rememberme"))
  
  const commonTranslation = translations?.common || {};

  const loginPageTranslation = translations?.loginPage || {};
  const [errors, setErrors] = useState({})
  /**
   * State Management
   */
  const [state, setState] = useState({
    email: "", 
    password: "",
    domain: "", 
    device_token: "",
    device_id: "",
    preUser: false,
    isDomainExists: false,
    isLoading: false
    
  })


  
  
  
  useEffect(() => {
    getTranslation()
  }, [lang])
  
  /**
   * Get Translation from server
   */
  const getTranslation = ()=>{
    dispatch(getTranslationHandler({
      category: 'loginPage',
      lang: lang
    }))
  }

  

   /**
   * Linkedin Social Media Hook
   */
   
  const { linkedInLogin } = useLinkedIn({
    clientId: process.env.REACT_APP_LINKEDIN_CLIENT_ID,
    redirectUri: `${window.location.origin}/linkedin`,
    scope: "r_emailaddress+r_liteprofile",
    state: `${Date.now()}`,
    onSuccess: async (code) => {
      const m_e = mixpanelActions.SIGN_UP_SS(lang,"linkedin")
      mixpanel.track(m_e.event, m_e.properties);
      socialLoginHandler(code,"linkedin")
      },
    onError: (error) => {
      console.log(error);
    },
  });

  /**
   * login api Call
   */
  
  const loginApiHandler = async()=>{
    // navigate("/signup",{state: {step: 4, user: {authKey: "sdadas"}}})
    // return
    setState({...state,isLoading: true})
    const data = {
      username: state.email, 
      password: state.password, 
      domain_name: state?.domain,
      device_token: "", 
      device_id: "",
      rememberMe: isRememberme,
      lang: lang
    }
    try {
      
      let res = await loginApi(data)
      res = JSON.parse(res)
      if(res.code === statusCode.K200){
        // setState({...state,isLoading: false})
        redirectUserToDomain(res)
        // const _res = setCookies(MyAccessTokenVar, res.data.access_token,100000,`.${process.env.REACT_APP_DOMAIN}`)
        // window.location.replace(`https://kinz3react2022w.dev.dot.jo/login?email=${res.data.email}`);
        // window.location.replace(`https://${res.data.domain_name}.${process.env.REACT_APP_DOMAIN}/login?email=${res.data.email}`);

      }else{
        if(res.code === statusCode.K401){
          redirectError(res,"simple")
        }
        else if(res.code === statusCode.K201){
          setState({...state, isLoading: true, process: "completed", user: {...res.data,...res.extra}})
          navigate(`/${toRoute}/signup/prepare-setup`,{replace: true})
        }
        else{
          if(res.errors?.two_factor_authentication){
            const error = Array.isArray(res.errors?.two_factor_authentication)?res.errors?.two_factor_authentication[0]: res.errors?.two_factor_authentication
            toast.error(error || "Something went wrong with social login")
            navigate(`/${toRoute}/login/varification`,{state:res.extra});
            return
          }
          if(res.errors?.message){
            toast.error(res.errors?.message || "Something went wrong with social login")
          }
    
          if(res.errors){
            setErrors(res.errors)
          }
          setState({...state, isLoading: false})
        }
        
      }
      
    } catch (error) {
      console.log(error)      
    }

    // dispatch(login({data}))
    return
  }

  const redirectError = (res, type)=>{
    
    if((Object.keys(res?.errors || {}))){
      
      if(res.errors?.domain){
        const error = Array.isArray(res.errors?.domain)?res.errors?.domain[0]: res.errors?.domain
        toast.success(error || "Something went wrong with social login")
        // navigate(`/${toRoute}/signup`,{state: {step: 3,type, user: {...res.data,...res.extra}}})
        navigate(`/${toRoute}/signup/domain`,{state:{user: {...res.data,...res.extra}}})
        return
      }
      if(res.errors?.profile){
        const error = Array.isArray(res.errors?.profile)?res.errors?.profile[0]: res.errors?.profile
        toast.success(error || "Something went wrong with social login")
        navigate(`/${toRoute}/signup/social-profile`,{state:{user: {...res.data,...res.extra}}})
        return
      }
     
      if(res.errors?.industry_name){
        const error = Array.isArray(res.errors?.industry_name)?res.errors?.industry_name[0]: res.errors?.industry_name
        toast.success(error || "Something went wrong with social login")
        navigate(`/${toRoute}/signup/prepare-setup`,{state:{user: {...res.data,...res.extra}}})
        // navigate(`/${toRoute}/signup`,{state: {step: 4,type, user: {...res.data,...res.extra}}})
        //  if(token){
        //    setDomainHandler(4);
        //  }
        return
      }

    }
    setState({...state,isLoading: false})
  }

  /**
   * Social Login Handler
   */
  
   const socialLoginHandler = async(accessToken, type)=>{

    try {
      let res = await loginSocialSignupApi({accessToken, type,lang:lang})
      res = JSON.parse(res)
      
      if(res.code === statusCode.K200){
        redirectUserToDomain(res)
      }else{
        redirectError(res,"social")
      }

    } catch (error) {
      
    }

    // dispatch(socialLogin({accessToken, type,lang:lang}))


  }

  const redirectUserToDomain = (res)=>{
    const _res = setCookies(MyAccessTokenVar, res.data.access_token,100000,`.${process.env.REACT_APP_DOMAIN}`)
    if(res?.data?.key){
      const _res2 = setCookies(MyRefreshTokenVar, res.data.access_token,100000,`.${process.env.REACT_APP_DOMAIN}`)
    }
    const _res1 = setCookies(SubDomain, res.data.domain_name,100000,`.${process.env.REACT_APP_DOMAIN}`)
    window.location.replace(`https://${res.data.domain_name}.${process.env.REACT_APP_DOMAIN}/dashboard`);
}

  const remembermeHandler  = ()=>{
    setIsRememberme(!isRememberme)
  }
  
  const handleChange = ({key, value})=>{
    setState({
      ...state,
      [key]: value
    })
  }

  /**
   * Entry Point
   */
  return (
    <div className="sign login">      
        <div className="sign__inner">
          <div className="signTitle">{loginPageTranslation["loginPage.log_in"]}</div>
          <div style={{marginTop: 20}}>
            <AppInput
              label = {commonTranslation["common.email_address"] || "Email address"}
              placeholder={commonTranslation["common.email_address"]}
              value={state.email}
              onChange = {({target})=>handleChange({key: "email", value: target?.value})}
              name="email"
              error = {(errors.email) ? <span className = {"error__text"}>{errors.email}</span> : null}
            />
            <AppInput
              label = {commonTranslation["common.password"] || "Password"}
              placeholder={commonTranslation["common.password"]}
              type="password" 
              id="password" 
              name="password" 
              value={state.password} 
              onChange = {({target})=>handleChange({key: "password", value: target?.value})}
              error = {errors.password ? <span className = {"error__text"}>{errors.password}</span> : null}
            />
            
            <div className="keeplogin-row">
              <div>
                <span className={""} style={{marginInlineEnd: 5}}>
                  <input type="checkbox" id="rememberme" name="rememberme" checked = {isRememberme} onClick = {remembermeHandler}/>
                </span>
                <span style={{display: "inline"}}  className={"signText"}>{loginPageTranslation["loginPage.keppMeLoggedIn"] || "Keep me logged in"}</span>
              </div>
              <Link to = {`/${toRoute}/forgot-password`} className="signText" style={{marginBottom: 0}}>
                {commonTranslation["common.forgot_password"] || "Forgot Password?"}
              </Link>
            </div>

            <AppButton
              title={commonTranslation["common.proceed"] || "Proceed"}
              isLoading = {state.isLoading}
              style = {{width: "100%"}}
              onClick = {loginApiHandler}
            />
          </div>
          <div className="signOr">
            <span>{commonTranslation["common.or_continue_with"] || "Or Continue With"}</span>
          </div>
          <div className="signInput__row">
          <div className="signInput__col social-btn">
          <GoogleLogin
            onSuccess={credentialResponse => {
              const m_e = mixpanelActions.SIGN_UP_SS(lang,"google")
              mixpanel.track(m_e.event, m_e.properties);
              socialLoginHandler(credentialResponse.credential,"google")
            }}
            onError={() => { console.log('Login Failed')}}
            useOneTap
          />
          
          </div>
          <div className="signInput__col social-btn">
            <button className="signMore" onClick = {linkedInLogin} style={{cursor: "pointer"}}>
              <img
                src={process.env.PUBLIC_URL + "/images/icons/linkedin.svg"}
                alt="avatar"
              />
              {commonTranslation["common.continue_with_linkedin"] || "Continue with linkedin"}
            </button>
          </div>
        </div>
          
        </div>
    </div>
  );
}

export default Login