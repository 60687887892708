import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import AppButton from "../../../Components/AppButton";
import { globalSvg } from "../../../assets/svgs";

export default function Step3({toRoute, setErrors,errors = {},translation,state, completeDomainHandler, setState, isLoading }) {
  const {translations} = useSelector(state => state.language);
  const commonTranslation = translations?.common || {};

  const handleChange = async(key, value)=>{
    const _errors = errors
    if(_errors[key]){
      delete _errors[key]
      setErrors(errors)
    }
    setState((state)=>({
      ...state,
      [key]: value
    }))
  }

  return (
    <>
      <div>
        <div className="signInput signInput-domain">
          <label htmlFor="">{translation["signupPage.please_enter_your_CRM_domain_name"] || "Please enter your CRM domain name"}</label>
          <div className="signInput__inner">
            <span className="signInput__icon">{globalSvg}</span>
            <input style={{borderRadius: 0, height: "100%"}} placeholder="CRM Domain Name" type="text" id="domain_name" name="domain_name" value={state.domain_name} onChange = {({target})=>handleChange("domain_name",target.value)}/> 
            <span className="signInput__name">.{process.env.REACT_APP_DOMAIN}</span>
          </div>
          {errors.domain_name ? <span className = {"error__text"}>{errors.domain_name}</span> : null}
        </div>
        <div className="signCheck">
          <div style={{display: 'flex', alignItems: 'center'}}>
            <div className="checkbox" style={{}}>
              <input type="checkbox" placeholder = {""} value = {true} onChange = {(e)=>{}}/>
              <label htmlFor=""></label>
            </div>
            <div style={{marginInlineStart: 10}}>
                <span className={"label"} htmlFor="">{translation["signupPage.agree"] || "AGREE"}</span>
                <Link target="_blank" to={`${process.env.REACT_APP_REDIRECT_URL}${toRoute}/pages/terms-of-service`} style={{fontWeight: "bold"}} className={"label"} htmlFor=""> {translation["signupPage.terms_of_service"] || "terms of Service"}</Link>
                <span className={"label"} htmlFor=""> {translation["signupPage.and"] || "signup page. and"} </span>
                <Link  target="_blank" to={`${process.env.REACT_APP_REDIRECT_URL}${toRoute}/pages/privacy-policy`} style={{fontWeight: "bold"}} className={"label"} htmlFor="">{translation["signupPage.privacy_policy"] || 'privacy policy'}</Link>
            </div>
          </div>
          {errors.agree? <span className = {"error__text"}>{errors.agree}</span> : null}
        </div>
        <AppButton
          isLoading = {isLoading}
          title = {commonTranslation["common.proceed"] || "Proceed"}
          onClick = {completeDomainHandler}
          style = {{width: "100%"}}
        
        />
      </div>
    </>
  );
}
