// ChatBot.js

import React, { useState, useRef } from 'react';
import './chat.css'
import { chatBotRoomListApi, editClientMessage, getRoomOldMessagesApi, sendMessageToSarverApi } from '../../apis';
import { colors, statusCode } from '../../Helper/constants';
import { useEffect } from 'react';
import ReactLoading from 'react-loading';
import AppButton from '../AppButton';
import AppInput from '../AppInput';

const ChatBot = () => {
    
    const [messages, setMessages] = useState([]);
    const messagesLength = useRef(0); // Create a ref for the latest messages

    const [editDefault, setEditDefault] = useState({
        isEdit: false,
        newMessage: ""
    })
    
    const [rooms, setRooms] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    
    const [editState, setEditState] = useState({
        isEdit: false,
        newMessage: "",
    });

    const [inputText, setInputText] = useState('');
    const chatboxRef = useRef(null);
    const [intervalId, setIntervalId] = useState(null);
    const [activeRoom, setActiveRoom] = useState(null)

    useEffect(() => {
        loadRoomListHandler()
    }, [])
  

  const loadRoomListHandler = async()=>{
    try {
        let res = await chatBotRoomListApi();
        res = JSON.parse(res)
        if(res.code === statusCode.K200){
            if(res?.data.length > 0){
                setRooms(res?.data)
                setActiveRoom(res?.data[0])
            }
            setEditDefault({isEdit: false, newMessage: res?.extra?.default_prompt})
        }
        
    } catch (error) {
        
    }
  }

  const handleRoomSwitch = (room) => {
    setMessages([]);
    setInputText('');
    setActiveRoom(room);
  };

  useEffect(() => {
    
    if (activeRoom?.room_id) {
      clearInterval(intervalId)
      setIsLoading("switchroom")
      const newIntervalId = setInterval(loadOldMessageApi, 5000);
      setIntervalId(newIntervalId);
    }
    
    if(editDefault.isEdit){
      setEditDefault({
          ...editDefault,
          isEdit: false
      })
    }
    
    // Clear the interval when activeRoom changes or the component is unmounted
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [activeRoom?.room_id]);


  const loadOldMessageApi = async()=>{
    try {
      
      let res = await getRoomOldMessagesApi({room_id: activeRoom?.room_id})
      res = JSON.parse(res)
      setIsLoading(false)

      if(res.code === statusCode.K200 && res?.data?.length !== messagesLength.current || messagesLength.current === 0){
          setMessages(res.data)
          messagesLength.current = res.data.length
          if (chatboxRef.current) {
            setTimeout(() => {
                chatboxRef.current.scrollTo(0, chatboxRef.current.scrollHeight);
                // chatboxRef.current.scrollTop = chatboxRef.current.scrollHeight;
                // chatboxRef.current.scrollIntoView({block: "end", inline: "nearest", behavior: 'smooth'})
              }, 500);
              
          }
        }

        //   if(){
            
        //         // Greet the user on switching
        //     // setMessages((prevMessages) => [
        //     //     ...prevMessages,
        //     //     { sender: 'Bot', text: room?.content || "" }
        //     // ]);
        // }
        
    } catch (error) {
        
    }
  }

  const handleSendMessage = async () => {
    if (inputText.trim() !== '') {
      setMessages((prevMessages) => [...prevMessages, { added_by: 'customer', content: inputText }]);
      setInputText('');
      setTimeout(() => {  chatboxRef.current.scrollTo(0, chatboxRef.current.scrollHeight)}, 500);
      try {
        setIsLoading("send")
        let res = await sendMessageToSarverApi({room_id: activeRoom?.room_id, q: inputText})
        res = JSON.parse(res)
        setIsLoading(false)
        if(res.code === statusCode.K200){
            console.log(res)
        }
    } catch (error) {
        
    }
      // Implement logic to send the message to the bot or handle user input here
    }
  };


  const handleEditMessage = async()=>{
    try {
        let res = await editClientMessage({room_id: activeRoom?.room_id,prompt_content: editState.newMessage})
        res = JSON.parse(res);
        if(res.code === statusCode.K200){
            setEditState({
                isEdit:false,
                newMessage: false                
            })
            // console.log(chatboxRef.current.scrollHeight,"________")
            // if(res?.data?.length !== messages.length || messages.length === 0 || true){
            //     if (chatboxRef.current) {
            //         chatboxRef.current.scrollTop = chatboxRef.current.scrollHeight;
            //     }
            //     setMessages(res.data)

            // }
                // Greet the user on switching
            // setMessages((prevMessages) => [
            //     ...prevMessages,
            //     { sender: 'Bot', text: room?.content || "" }
            // ]);
        }
        
    } catch (error) {
        
    }
  }

  const handleOnEdit = (message)=>{
      if(editState?.isEdit){
          setEditState({
              isEdit: false,
              newMessage: ''
          })

      }else{
          setEditState({
              isEdit: true,
              newMessage: message?.content || "",
              ...message
          })

      }
  }


  const updateDefaultPrompt = async()=>{
    try {
        setIsLoading("default-promt")
        let res = await editClientMessage({room_id: "default",prompt_content: editDefault.newMessage})
        res = JSON.parse(res);
        setIsLoading("")
        if(res.code === statusCode.K200){
            setEditDefault({
                ...editDefault,
                isEdit:false,
            })
            // console.log(chatboxRef.current.scrollHeight,"________")
            // if(res?.data?.length !== messages.length || messages.length === 0 || true){
            //     if (chatboxRef.current) {
            //         chatboxRef.current.scrollTop = chatboxRef.current.scrollHeight;
            //     }
            //     setMessages(res.data)

            // }
                // Greet the user on switching
            // setMessages((prevMessages) => [
            //     ...prevMessages,
            //     { sender: 'Bot', text: room?.content || "" }
            // ]);
        }
        
    } catch (error) {
        
    }
  }



  return (
    <div className="chatbot-container">
    <div className={isLoading === "switchroom"?"overlay": "overlay-hide"}>
        <ReactLoading type={"cylon"} color={"white"} height = {50} width = {50}/>
    </div>
      <div className="sidebar">
        <div className='row'>
            <h2>Room</h2>
            {
                editDefault?.isEdit?
                <div className='row'>
                    <AppButton title={"Update"} onClick={updateDefaultPrompt}  isLoading = {isLoading === "default-promt"}/>
                    <AppButton  style={{marginInlineStart: 5, backgroundColor: colors.color, borderWidth: 0}} onClick={()=>setEditDefault({...editDefault, isEdit: false})}>
                        <i className="fas fa-times"></i>
                    </AppButton>
                </div>
                :
                <AppButton title={"Edit Default"} onClick={()=>{ 
                    setEditDefault({...editDefault,isEdit: true})
                }}/>
            }
        </div>
        {
            editDefault.isEdit?
                <div>
                    <textarea
                        type="text"
                        value={editDefault?.newMessage || ""}
                        onChange={({target}) => setEditDefault({isEdit: true, newMessage: target.value})}
                        placeholder="Type your message..."
                    />
                    {/* <AppButton title={"Update"} onClick={updateDefaultPrompt} /> */}
                </div>
            :
            <></>
        }
        <ul>
          {rooms.length > 0 && rooms.map((room) => (
            <li key={room?.id} onClick={() => handleRoomSwitch(room)} className={room?.room_id === activeRoom?.room_id ? 'active' : ''}>
              {`${room?.room_name || room?.added_by}`} 
              {/* {`${room?.room_id} (${room?.added_by})`}  */}
            </li>
          ))}
        </ul>
      </div>
      <div className="chatbox">
        <div className="chatbox-messages" id = {"chatbox"} ref={chatboxRef}>
          {/* Display chat messages here */}
          {messages.map((message,index) => (
            <div key={message.id} 
              className={
                message.added_by === 'user' ? 'user-message single-message' :   //green
                message.added_by === 'assistant'? 'client-message single-message' : //orange
                'bot-message single-message' // puple
              }
            >
                {
                    // message.added_by != 'chatgpt' && message.added_by != 'customer'?
                    index === 0?
                    <div className='chat-box-actions'>
                        {
                            editState?.isEdit?
                            <span onClick={()=>handleOnEdit(message)}>
                                <i className="fas fa-times-circle"></i>
                            </span>
                            :
                            <span onClick={()=>handleOnEdit(message)}>
                                <i className="fas fa-edit"></i>
                            </span>
                        }
                    </div>
                    :
                    <></>
                
                }
                <span>{message.added_by}: </span>
                <span>{message.content}</span>
                {
                    (editState?.id && `${editState?.id}-${editState?.room_id}` === `${message?.id}-${message?.room_id}`)?
                        <div className="chatbox-input">
                            <textarea
                                type="text"
                                value={editState?.newMessage || ""}
                                onChange={({target}) => setEditState({...editState, newMessage: target.value})}
                                placeholder="Type your message..."
                            />
                            <AppButton style={{marginInlineStart: 10}} title={"Update"} onClick={handleEditMessage}/>
                        </div>
                    :
                    <></>
                }
            </div>
          ))}
        </div>
        {
          rooms.length > 0?
          <div className="chatbox-input">
            <input
              type="text"
              value={inputText}
              onChange={(e) => setInputText(e.target.value)}
              placeholder="Type your message..."
            />

            <AppButton onClick={handleSendMessage} isLoading = {isLoading === "send"}>
              <i className="fas fa-paper-plane"></i>
            </AppButton>
          </div>
          :
          <></>
        }
      </div>
    </div>
  );
};

export default ChatBot;
