import React from 'react';
import Header from './Header'  

const Page404= () =>{
    return (
        <>
        {/* <Header/> */}
        <div className="sign">      
            {/* <div className="sign__inner"> */}
                <div style={{color: "#fff"}}>
                    <h1>404 Error</h1>
                    <h1>Page Not Found</h1>
                </div>
            {/* </div> */}
        </div>
        </>
    )
}
  
export default Page404;