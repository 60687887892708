import React, { useState, useEffect, useRef } from "react";
import Step1 from "./Steps/Step1";
import Step2 from "./Steps/Step2";
import Step3 from "./Steps/Step3";
import Step7 from "./Steps/Step7";

import { checkEnvironmentStatus, completeDomainApi, completeIndustriApi, getPricingCardsApi, industriesListApi, loginSocialSignupApi, signUpApi, socialSignupCompleteApi, } from '../../apis'
// import { statusCode } from "../../helper/constants";
import { useLinkedIn, } from 'react-linkedin-login-oauth2';
import { GoogleLogin, useGoogleLogin, useGoogleOneTapLogin,  } from '@react-oauth/google';
import {checkPlusIsExists, setCookies} from '../../Helper/functions'
// import useTokens from '../../Hooks/useTokens'

import moment from 'moment';
import 'moment-timezone';

import {useDispatch, useSelector} from 'react-redux'
import { getTranslationHandler } from "../../redux/features/language";
import { useLocation, useNavigate, useParams } from "react-router";
// import { register, registerDomain, registerIndestri, reset, socialLogin, socialSignup, socialSignupProfileComplete } from "../../redux/features/auth/authSlice";

import { toast } from "react-toastify";
import queryString from 'query-string'
import AppButton from "../../Components/AppButton";
import Progressbar from "../../Components/Progressbar";
import { mixpanelActions, MyAccessTokenVar, MyRefreshTokenVar, statusCode, SubDomain } from "../../Helper/constants";
import { useSearchParams } from "react-router-dom";
import mixpanel from "mixpanel-browser";

const whitelist = ["profile","social-profile","domain","prepare-setup","thank-you"]

let interval = null
export default function SignUp({toRoute}) {
  
  const location = useLocation();
  const {signupStep} = useParams()
  // const signupStep = "prepare-setup"
  
  const navigate = useNavigate()
  const {status, lang, translations} = useSelector(state => state.language);
  const {lookups} = useSelector(state => state.lookups);
  const [plans, setPlans] = useState({data: {}, extra: {}})
  const [errors, setErrors] = useState({})
  
  const signupPageTranslation = translations?.signupPage || {};
  const commonTranslation = translations?.common || {};
  
  const parsed = queryString.parse(window.location.search);
  
  const dispatch = useDispatch();
  const [industriesList, setIndustriesList] = useState({
    data: []
  })

  const [timer, setTimer] = useState(0)

  /**
   * State Management
   */
   const [state, setState] = useState({
    type: location?.state?.type || "simple",
    // type: "social",
    first_name: "",
    last_name: "",
    username: "",
    email: "",
    password: "",
    password_confirm: "",
    code: lookups?.data?.countryCode?`+${lookups?.data?.countryCode}`: "+962",
    mobile: "",
    company_name: "",
    location: "",
    reCaptcha: "",
    agree: false,
    isLoading: false,
    socialError: "",
    domain_name: location?.state?.user?.domain_name || "",
    user: location?.state?.user || "",
    process: ""
  })
  
  // const [step, setStep] = useState(location?.state?.step?location.state.step:1);
  // const [init, setInit] = useState(location?.state?.init?true:true)

  const reCaptchaRef = useRef(null)
  
  useEffect(() => {
    console.log(signupStep)
    if(!state.user?.auth_key &&  signupStep && signupStep !== "profile"){
      if(parsed?.id){
        navigate(`/${toRoute}/signup?id=${parsed?.id}`)
      }else{
        navigate(`/${toRoute}/signup`)
      }
    }
  }, [])

  // const getPricingHandler = async()=>{
  //   let res = await getPricingCardsApi(lang)
  //   res = JSON.parse(res)
  //   if(res.code === statusCode.K200){
  //     setPlans({data:res.data, extra: res.extra})
  //   }

  // }
  

  useEffect(() => {
    
    if(state.process === "completed"){
      checkEnvironmentStatusHandler()
      interval = setInterval(() => checkEnvironmentStatusHandler(), 3000)
      return () => {
        clearInterval(interval);
      }
    }
  }, [state.process])

  const checkEnvironmentStatusHandler = async()=>{
    setTimer(timer=> timer + 3)
    try {
      let res = await checkEnvironmentStatus({lang, auth_key:state?.user?.auth_key})
      res = JSON.parse(res)
      
      if(res.code === statusCode.K200){
        if(res.data.env_status == "completed"){
          
          const m_e = mixpanelActions.COMPELTE_ON_SUCCESS(lang)
          mixpanel.track(m_e.event, m_e.properties);
          
          // if(window?.snaptr && process.env.REACT_APP_SNAPCHAT_PIXAL){
          //   window.snaptr('init', process.env.REACT_APP_SNAPCHAT_PIXAL, {
          //   'user_email': process.env.REACT_APP_SNAPCHAT_EMAIL
          //   });
          // }
          
          redirectUserToDomain(res)
          // window.location.replace(`https://kinz3react2022w.dev.dot.jo/dashboard`);
          if(interval){
            clearInterval(interval);
            // const res = setAccessToken(res.data.access_token)
            // console.log(res,"ACCESS TOKEN IS SET")
            // if(res){
            // }
          }
        }
      }
    } catch (error) {
      navigate(-1)
    }
  }
  
  const redirectUserToDomain = (res)=>{
    const _res = setCookies(MyAccessTokenVar, res.data.access_token,100000,`.${process.env.REACT_APP_DOMAIN}`)
    if(res?.data?.key){
      const _res2 = setCookies(MyRefreshTokenVar, res.data.access_token,100000,`.${process.env.REACT_APP_DOMAIN}`)
    }
    const _res1 = setCookies(SubDomain, res.data.domain_name,100000,`.${process.env.REACT_APP_DOMAIN}`)
    window.location.replace(`https://${res.data.domain_name}.${process.env.REACT_APP_DOMAIN}/dashboard`);
  }
  
  useEffect(() => {
    if(lookups?.data?.countryCode){
      setState({...state, code: `+${lookups?.data?.countryCode}`})
    }
  }, []);
  
  useEffect(() => {
    getIndustriesHandler()
    getTranslation();
  }, [lang])

    /**
   * getTranslations
   */
    const getTranslation = ()=>{
      dispatch(getTranslationHandler({
        category:  'signupPage',
        lang: lang
      }))
    }
  

  useEffect(() => {
    if(lookups?.data?.countryCode){
    
      setState({...state, code: `+${lookups?.data?.countryCode}`})
    }
  }, [lookups?.data?.countryCode]);

  const getIndustriesHandler  = async()=>{
    try {
      let res  = await industriesListApi(lang)
      res =JSON.parse(res)
      if(res.code === statusCode.K200){
        setIndustriesList({data:res.data})
      }
    } catch (error) {
      
    }
  }
  
  
  /**
   * Submit Form Step 1
   */
  const submitForm = async ()=>{
    try {
      
      const zone     =  moment?.tz?.guess();
      const timezone =  moment?.tz(zone)?.zoneAbbr();
      const mobile   =  checkPlusIsExists(state?.mobile?.replace(/[-\s_]/g, ""))
      let recaptchaValue = await reCaptchaRef?.current?.executeAsync();
      setState({...state, isLoading: true})

      let res = await signUpApi({...state,plan_id: parsed?.id, reCaptcha: recaptchaValue || "", timezone: timezone, mobile: mobile, lang: lang})
      res = JSON.parse(res)
      reCaptchaRef?.current?.reset()
      
      if(res.code === statusCode.K200){
        const m_e = mixpanelActions.GENETAL_INFO_ON_COMPLETE(lang)
        mixpanel.track(m_e.event, m_e.properties);
        if(res?.data?.auth_key){
          // setState({...state, isLoading: false, user: {...res.data,...res.extra}, domain_name: res.data.domain_name})
          // navigate(`/${toRoute}/signup/domain`,{replace: true})
          // setState({...state, isLoading: false, user: {...res.data,...res.extra}})
          setState({...state, isLoading: true, process: "completed", user: {...res.data,...res.extra}})
          navigate(`/${toRoute}/signup/prepare-setup`,{replace: true})
        }else{
          setState({...state, isLoading: false})
        }
      }else{
        if(res?.errors){
          setErrors(res.errors)
        }
        setState({...state, isLoading: false})
      }

      // dispatch(register({data:{...state,plan_id: parsed?.id, reCaptcha: recaptchaValue || "", timezone: timezone, mobile: mobile, lang: lang}}))
      
      // setState({...state, isLoading: false})
      
    } catch (error) {
      setState({...state, isLoading: false})
      console.log(error)
    }
 
  }


  /**
   * Linkedin Social Media Hook
   */

   const { linkedInLogin } = useLinkedIn({
    clientId: process.env.REACT_APP_LINKEDIN_CLIENT_ID,
    redirectUri: `${window.location.origin}/linkedin`,
    scope: "r_emailaddress r_liteprofile",
    onSuccess: async (code) => {
      const m_e = mixpanelActions.SIGN_UP_SS(lang,"linkedin")
      mixpanel.track(m_e.event, m_e.properties);
      socialLoginHandler(code,"linkedin")
    },
    onError: (error) => {
      console.log(error);
    },
  });

  


  /**
   * Social Login Handler
   */
  
  const socialLoginHandler = async(accessToken, type)=>{
    try {
      let res = await loginSocialSignupApi({accessToken,type,lang:lang,plan_id: parsed?.id})
      res = JSON.parse(res)
      
      if(res.code === statusCode.K200){
        redirectUserToDomain(res)
      }
      else if(res.code === statusCode.K201){
        setState({...state, isLoading: true, process: "completed", user: {...res.data,...res.extra}})
        navigate(`/${toRoute}/signup/prepare-setup`,{replace: true})
      }
      else{
        redirectError(res)
      }

    } catch (error) {
      
    }
  }

  const redirectError = (res)=>{
    
    if((Object.keys(res?.errors || {}))){
      if(res.errors?.two_factor_authentication){
        navigate(`/${toRoute}/login/varification`);
        return
      }
     
      if(res.errors?.domain){
        if(res.code === statusCode.K401){
          const error = Array.isArray(res.errors?.domain)?res.errors?.domain[0]: res.errors?.domain
          toast.success(error || "Something went wrong with social login")
          navigate(`/${toRoute}/signup/domain`,{replace: true})
          setState({
            ...state,
            type: "social",
            isLoading: false,
            // user: {...res.data,...res.extra}
            user: {...res.data,...res.extra}, 
            domain_name: res.data.domain_name
          })
        }
        return
      }
     
      if(res.errors?.profile){
        if(res.code === statusCode.K401){
          const error = Array.isArray(res.errors?.profile)?res.errors?.profile[0]: res.errors?.profile
          toast.success(error || "Something went wrong with social login")
          
          navigate(`/${toRoute}/signup/social-profile`,{replace: true})
          setState({
            ...state,
            type: "social",
            isLoading: false,
            user: {...res.data,...res.extra}, 
            domain_name: res.data.domain_name
          })
        }
        return
      }
     
      if(res.errors?.industry_name){
        if(res.code === statusCode.K401){
          const error = Array.isArray(res.errors?.industry_name)?res.errors?.industry_name[0]: res.errors?.industry_name
          toast.success(error || "Something went wrong with social login")
          setState({
            ...state,
            isLoading: false,
            type: "social",
            user: {...res.data,...res.extra}, 
            domain_name: res.data.domain_name
          })
          navigate(`/${toRoute}/signup/prepare-setup`,{replace: true})
        }
        //  if(token){
        //    setDomainHandler(4);
        //  }
        return
      }

      if(res.errors?.message){
        toast.error(res.errors?.message || "Something went wrong with social login")
      }

      if(res.errors){
        setErrors(res.errors)
        setState({...state,isLoading: false})
      }

    }
  }


  /**
   * Complete sign up form step 2
   */
  const signUpComplete = async()=>{

      const mobile   =  checkPlusIsExists(state?.mobile?.replace(/[-\s_]/g, ""))
      const data = {
        auth_key: state.user?.auth_key,
        mobile: mobile,
        company_name: state.company_name,
        plan_id: parsed?.id || "",
        lang: lang
      }
      try {
        setState({...state, isLoading: true})
        let res = await socialSignupCompleteApi(data)
        res = JSON.parse(res)
        if(res.code === statusCode.K200){
          const m_e = mixpanelActions.MOBILE_NUMBER_AND_COMANY_ON_SUCCESS(lang)
          mixpanel.track(m_e.event, m_e.properties);
          if(res?.data?.auth_key){
            setState({...state, isLoading: false, user: {...res.data,...res.extra}, domain_name:res.data?.domain_name})
            navigate(`/${toRoute}/signup/domain`,{replace: true})
          }else{
            setState({...state, isLoading: false})
          }
        }else if(res.code === statusCode.K201){
          setState({...state, isLoading: true, process: "completed", user: {...res.data,...res.extra}})
          navigate(`/${toRoute}/signup/prepare-setup`,{replace: true})
        }
        else{
          if(res?.errors){
            setErrors(res.errors)
          }
          setState({...state, isLoading: false})
        }
      } catch (error) {
        console.log(error)
      }
      // dispatch(socialSignupProfileComplete({data:data, setErrors}))
      // return

  }
  

  /**
    * Domain Handler Step 3
    */

  const completeDomainHandler  = async ()=>{
  
    const data = {
      auth_key: state?.user?.auth_key || "",
      domain_name: state?.domain_name,
      agree: state.agree,
      lang: lang
    }
    try {
      setState({...state, isLoading: true})
      let res = await completeDomainApi(data);
      res = JSON.parse(res)
      if(res.code === statusCode.K200){
        const m_e = mixpanelActions.DOMAIN_NAME_ON_SUCCESS(lang)
        mixpanel.track(m_e.event, m_e.properties);
        if(res?.data?.auth_key){
          setState({...state, isLoading: false, user: {...res.data,...res.extra}})
          navigate(`/${toRoute}/signup/prepare-setup`,{replace: true})
        }else{
          setState({...state, isLoading: false})
        }
      }else if(res.code === statusCode.K201){
        setState({...state, isLoading: true, process: "completed", user: {...res.data,...res.extra}})
        navigate(`/${toRoute}/signup/prepare-setup`,{replace: true})
      }
      else{
        if(res?.errors){
          setErrors(res.errors)
        }
        setState({...state, isLoading: false})
      }
      
    } catch (error) {
      
    }
    // dispatch(registerDomain({data:data, setErrors}))

  }
  
  /**
    * Domain Handler Step 4
    */

  const completeIndesriProcess  = async (values)=>{

    // if(values?.type === "Others"){
    //   const m_e = mixpanelActions.INDUSTRY_ON_SELECT(lang, values?.value)
    //   mixpanel.track(m_e.event, m_e.properties);
    // }

    const data = {
      auth_key: state.user?.auth_key,
      // industry_name: "",
      other_industry: values?.type === "Others"? values.value: "",
      industry_name: values?.type || "",
      lang: lang
    }
    
    const m_e = mixpanelActions.COMPELTE_PROCESS_ON_CLICK(lang)
    mixpanel.track(m_e.event, m_e.properties);
    
    try {
      
      
      let res = await completeIndustriApi(data); //API CALL
      res = JSON.parse(res)
      
      
      if(res.code === statusCode.K200){
        if(res?.data?.auth_key){
          const m_e = mixpanelActions.COMPELTE_PROCESS_CLICK_ON_SUCCESS(lang)
          mixpanel.track(m_e.event, m_e.properties);
          setState({...state, isLoading: true, process: "completed", user: {...res.data,...res.extra}})
          // setState({...state, isLoading: false, user: res.data,})
        }else{
          setState({...state, isLoading: false})
        }
      }else{
        if(res?.errors){
          setErrors(res.errors);
          const error = Array.isArray(res.errors?.industry_name)?res.errors?.industry_name[0]: res.errors?.industry_name
          toast.error(error || "Something went wrong with social login")
        }
        setState({...state, isLoading: false})
      }
      
    } catch (error) {
      
    }

  }

  // useGoogleOneTapLogin({
  //   onError: error=>{
  //     console.log(error,"AZ")
  //   },
  //   onSuccess: codeResponse => {
  //     socialLoginHandler(codeResponse?.credential,"google")
  //   },
  //   // flow: 'auth-code',
  // });

  // const login = useGoogleLogin({
  //   onSuccess: async tokenResponse => {
  //     console.log(tokenResponse);
  //     // fetching userinfo can be done on the client or the server
  //     const userInfo = await axios
  //       .get('https://www.googleapis.com/oauth2/v3/userinfo', {
  //         headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
  //       })
  //       .then(res => res.data);

  //     console.log(userInfo);
  //   },
  //   // flow: 'implicit', // implicit is the default
  // });
  
  
  // const login = useGoogleLogin({
  //   onSuccess: codeResponse => {
  //     socialLoginHandler(codeResponse?.credenctial,"google")
  //   },
  //   flow: 'auth-code',
  // });

  const reset = ()=>{
    setState({
      type: "simple",
      first_name: "",
      last_name: "",
      username: "",
      email: "",
      password: "",
      password_confirm: "",
      code: lookups?.data?.countryCode?`+${lookups?.data?.countryCode}`: "+962",
      mobile: "",
      company_name: "",
      location: "",
      reCaptcha: "",
      agree: false,
      isLoading: false,
      socialError: "",
      domain_name: location?.state?.user?.domain_name || "",
      user: location?.state?.user || "",
    })
    navigate(-1)
    // setInit(true)
  }

  /**
   * Entery Point
   */


  const step = whitelist.indexOf(signupStep) > -1? whitelist.indexOf(signupStep) + 1: 0

  return (
    <div className={`sign ${signupStep === "prepare-setup"?"wider":""}`}>
      
      <div className="auto__container">
        {
          (signupStep === "" || signupStep === undefined)?
          <div className="sign__inner">
            <div className="signTitle">{signupPageTranslation["signupPage.signup"]}</div>
            <div className="signText">{signupPageTranslation["signupPage.signup_heading"]}</div>
            <AppButton
              title={signupPageTranslation["signupPage.signup_with_email"] || "Sign Up with Email"}
              onClick = {()=>{
                const m_e = mixpanelActions.SIGN_UP_EMAIL(lang)
                mixpanel.track(m_e.event, m_e.properties);
                if(parsed?.id){
                  navigate(`/${toRoute}/signup/profile?id=${parsed?.id}`,{replace: true})
                }else{
                  navigate(`/${toRoute}/signup/profile`,{replace: true})
                }
              }}
              style = {{width: "100%", marginTop: 30}}
            />
            <div className="signOr">
              <span>{commonTranslation["common.or_continue_with"] || "Or Continue With"}</span>
            </div>
            <div className="signInput__row">
              <div className="signInput__col social-btn">
              <GoogleLogin
                onSuccess={credentialResponse => {
                  const m_e = mixpanelActions.SIGN_UP_SS(lang,"google")
                  mixpanel.track(m_e.event, m_e.properties);
                  socialLoginHandler(credentialResponse.credential,"google")
                }}
                onError={() => {
                  console.log('Login Failed');
                }}
                useOneTap
              />
              
              </div>
              <div className="signInput__col social-btn">
                <button className="signMore" onClick = {linkedInLogin} style={{cursor: "pointer"}}>
                  <img
                    src={process.env.PUBLIC_URL + "/images/icons/linkedin.svg"}
                    alt="avatar"
                  />
                  {commonTranslation["common.continue_with_linkedin"] || "Continue with linkedin"}
                </button>
              </div>
            </div>
          </div>  
          :
          <div className="sign__inner">
          {
            signupStep === "profile"?
            <div className="signTitle">{signupPageTranslation["signupPage.signup"] || "Signup"}</div>
            :
            signupStep === "social-profile"?
            <div className="signTitle">{signupPageTranslation["signupPage.signup_complete"] || "Signup Complete"}</div>
            :
            signupStep === "domain"?
              <div className="signTitle">{signupPageTranslation["signupPage.enter_your_domain"] || "Enter your domain"}</div>
            :
            signupStep === "prepare-setup" && timer  === 0?
              <div className="signTitle">{commonTranslation["common.your_industry"] || "Your Industry"}</div>
              :
            signupStep === "prepare-setup" && timer > 0?
              <div className="signTitle" style={{margin: 'auto', maxWidth: "70%", textAlign: 'center'}} >
                {commonTranslation["common.please_wait_while_setup"] || "Please wait while we set you up, this may take a moment."}
              </div>
              :
            <></>

          }
          {
            signupStep === "prepare-setup"?
              <div className="signText">
                {/* <p style={{color: "#A5A5A5"}}>{
                    state.isLoading?
                    commonTranslation["common.setting_up_customised_experience"] || "Setting up customised experience, this may take a moment..."
                    :
                    commonTranslation["common.your_industry_under_header"] || "Before we get started, we want to make sure you have the best possible experience. Can you tell us what industry you're in?"
                  }
                </p> */}
              </div>
            :
              <div className="signText">{signupPageTranslation["signupPage.signup_heading"]}</div>
          }
          
            <div style={{marginTop: 10, marginBottom: 30}}>
              <Progressbar 
                activeStep={state.type === "simple"?step>1?step-1: step: step} 
                steps={state.type === "simple"?["1","2","3"]: ["1","2","3","4"]} />
            </div>

            {signupStep === "profile" && <Step1 translation = {signupPageTranslation} state = {state} submitForm = {submitForm} setState = {setState} reCaptchaRef= {reCaptchaRef} isLoading ={state.isLoading} plans = {plans.data} plansExtra = {plans.extra} goBackHandler={reset} errors= {errors} setErrors = {setErrors}/> }
            {signupStep === "social-profile" && <Step2 translation = {signupPageTranslation} state = {state} signUpComplete = {signUpComplete} setState = {setState} user = {state.user} isLoading = {state.isLoading} plans = {plans.data} plansExtra = {plans.extra} errors = {errors} setErrors = {setErrors} /> }
            {signupStep === "domain" && <Step3 toRoute={toRoute} translation = {signupPageTranslation} state = {state} completeDomainHandler = {completeDomainHandler} setState = {setState} user = {state.user} isLoading= {state.isLoading} errors = {errors} setErrors = {setErrors}/> }
            {signupStep === "prepare-setup" && <Step7 errors = {errors} isLoading = {state.isLoading} list = {industriesList?.data}  submitForm = {completeIndesriProcess} timer = {timer} setState = {setState} state = {state} setErrors = {setErrors}/> }
          </div>
        }
      </div>
    </div>
  );
}