const baseURL = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_VERSION
// const baseURLAuth = process.env.REACT_APP_AUTH_BASE_URL + process.env.REACT_APP_VERSION

export const industriesListApi = async(lang)=>{
  
  const requestOptions = {
    method: 'GET',
    // body: formdata,
    redirect: 'follow'
  };
  const response = await fetch(`${baseURL}/auth/default/industries-list?lang=${lang}`, requestOptions)
  return response.text()
  
}


export const getTranslation = async(type, category)=>{
  
  const requestOptions = {
    method: 'GET',
    redirect: 'follow'
  }
  const response = await fetch(`${baseURL}/utilities/default/translation?lang=${type}&category=${category}`, requestOptions)
  return response.text()

}

export const utilitiesLookupApi = async(data)=>{
  
  const requestOptions = {
    method: 'GET',
    redirect: 'follow'
  }
  const response = await fetch(`${baseURL}/utilities/default/lookup`, requestOptions)
  return response.text()
}

export const contactForm = async(data, lang)=>{
  
  const myHeaders = new Headers();
  const formdata = new FormData();
  
  // formdata.append("first_name", data.first_name);
  // formdata.append("last_name", data.last_name);
  formdata.append("full_name", data.full_name);
  formdata.append("email", data.email);
  formdata.append("mobile", `${data.mobile.replace(/[-\s_]/g, "")}`);
  formdata.append("company_name", data.company_name);
  formdata.append("reCaptcha", data.reCaptcha);
  formdata.append("lang", lang);
  
  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: formdata,
    redirect: 'follow'
  };
  
  const response = await fetch(`${baseURL}/site/default/contact-us`, requestOptions)
  return response.text()

}

export const checkPasswordStrengthApi= async(password, lang)=>{
  
  const formdata = new FormData();
  formdata.append("password", password);
  formdata.append("lang", lang);
  
  const requestOptions = {
    method: 'POST',
    headers: {
      "accept": "*/*"
    },
    body: formdata,
    redirect: 'follow'
  };

  const response = await fetch(`${baseURL}/auth/default/password-strength`, requestOptions)
  return response.text()
    
}

export const signUpApi = async(data)=>{
  
  const formdata = new FormData();
  formdata.append("first_name", data.first_name);
  formdata.append("last_name", data.last_name);
  formdata.append("email", data.email);
  formdata.append("mobile", data.mobile);
  formdata.append("company_name", data.company_name);
  formdata.append("timezone", data.timezone);
  formdata.append("password", data.password);
  formdata.append("password_confirm", data.password_confirm);
  formdata.append("reCaptcha", data.reCaptcha);
  formdata.append("lang", data.lang);
  formdata.append("plan_id", data?.plan_id || "");
  // formdata.append("agree", data.agree);
  

  const requestOptions = {
    method: 'POST',
    body: formdata,
    redirect: 'follow'
  };
  
  const response = await fetch(`${baseURL}/auth/default/signup`, requestOptions)
  return response.text()
  
}

export const loginApi = async({rememberMe,lang,username, password, device_token, device_id})=>{
  const formdata = new FormData();
  // formdata.append("username", username);
  formdata.append("email", username);
  formdata.append("password", password);
  formdata.append("device_token", device_token);
  formdata.append("device_os", "web");
  formdata.append("device_id", device_id);
  formdata.append("changeDeviceID", false);
  formdata.append("lang", lang);
  formdata.append("rememberMe", rememberMe?1:0);
  const requestOptions = {
    method: 'POST',
    headers: {
      "accept": "*/*"
    },
    body: formdata,
    redirect: 'follow'
  };

  try {
    const response = await fetch(`${baseURL}/auth/default/login`, requestOptions)
    return response.text()
  } catch (error) {
    return {
      isApiFailed: true,
      error: error
    }
  }
    
}

export const loginSocialSignupApi = async(data)=>{
  
  const myHeaders = new Headers();
  const formdata = new FormData();

  myHeaders.append("accept", "*/*");
  // myHeaders.append("Content-Type", "application/json");
  formdata.append("access_token", `${data.accessToken}`);
  formdata.append("source", data.type);
  if(data.plan_id){
    formdata.append("plan_id", data.plan_id);

  }
  formdata.append("lang", data.lang);

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: formdata,
    redirect: 'follow'
  };
  const response = await fetch(`${baseURL}/auth/default/social`, requestOptions) 
  return response.text()

}

export const completeDomainApi = async({lang,auth_key,domain_name, agree})=>{
  
  const formdata = new FormData();
  formdata.append("auth_key", auth_key);
  formdata.append("domain_name", domain_name);
  formdata.append("agree", agree);
  formdata.append("lang", lang);

  const requestOptions = {
    method: 'POST',
    headers: {
      "accept": "*/*"
    },
    body: formdata,
    redirect: 'follow'
  };


  try {
    const response = await fetch(`${baseURL}/auth/default/complete-domain`, requestOptions) 
    return response.text()
  } catch (error) {
    return {
      isApiFailed: true,
      error: error
    }
  }
}

export const completeIndustriApi = async({lang,auth_key,industry_name,other_industry})=>{
  
  const formdata = new FormData();
  formdata.append("auth_key", auth_key);
  formdata.append("industry_name", industry_name);
  if(other_industry){
    formdata.append("other_industry", other_industry);
  }
  // formdata.append("agree", agree);
  formdata.append("lang", lang);

  const requestOptions = {
    method: 'POST',
    headers: {
      "accept": "*/*"
    },
    body: formdata,
    redirect: 'follow'
  };


  try {
    const response = await fetch(`${baseURL}/auth/default/complete-industry`, requestOptions) 
    return response.text()
  } catch (error) {
    return {
      isApiFailed: true,
      error: error
    }
  }
}

export const socialSignupCompleteApi = async({plan_id,lang,auth_key,mobile,company_name})=>{
  
  const formdata = new FormData();
  formdata.append("auth_key", auth_key);
  formdata.append("mobile", mobile);
  formdata.append("company_name", company_name);
  formdata.append("plan_id", plan_id || "");
  formdata.append("lang", lang);

  const requestOptions = {
    method: 'POST',
    headers: {
      "accept": "*/*"
    },
    body: formdata,
    redirect: 'follow'
  };


  try {
    const response = await fetch(`${baseURL}/auth/default/complete-signup-social`, requestOptions) 
    return response.text()
  } catch (error) {
    return {
      isApiFailed: true,
      error: error
    }
  }

  
}

export const checkEnvironmentStatus = async({auth_key, lang})=>{
  
  const formdata = new FormData();
  formdata.append("auth_key", auth_key);
  formdata.append("lang", lang);

  const requestOptions = {
    method: 'POST',
    headers: {
      "accept": "*/*"
    },
    body: formdata,
    redirect: 'follow'
  };


  try {
    const response = await fetch(`${baseURL}/auth/default/check-environment-status`, requestOptions) 
    return response.text()
  } catch (error) {
    return {
      isApiFailed: true,
      error: error
    }
  }

  
}


export const passwordResetApi = async({email,reCaptcha,lang})=>{
  
  const formdata = new FormData();
  formdata.append("email", email);
  formdata.append("reCaptcha", reCaptcha);
  formdata.append("lang", lang);
  
  const requestOptions = {
    method: 'POST',
    headers: {
      "accept": "*/*"
    },
    body: formdata,
    redirect: 'follow'
  };

  try {
    const response = await fetch(`${baseURL}/auth/default/password-reset-request-form`, requestOptions)
    return response.text()
  } catch (error) {
    return {
      isApiFailed: true,
      error: error
    }
  }
}

export const twoStepVarificationApi = async({code,domain_name})=>{
  
  const formdata = new FormData();
  formdata.append("code", code);
  formdata.append("domain_name", domain_name);
  
  const requestOptions = {
    method: 'POST',
    headers: {
      "accept": "*/*"
    },
    body: formdata,
    redirect: 'follow'
  };
  
  const response = await fetch(`${baseURL}/auth/default/two-factor-authentication`, requestOptions)
  return response.text()
  
}


export const resendTFAapi = async(auth_key,domain_name)=>{
  
  
  const formdata = new FormData();
  formdata.append("domain_name", domain_name);
  formdata.append("auth_key", auth_key);
  const requestOptions = {
    method: 'POST',
    headers: {
      "accept": "application/json"
    },
    body: formdata,
    redirect: 'follow'
  }
  

  const response = await fetch(`${baseURL}/auth/default/resend-two-factor-authentication`, requestOptions)
  return response.text()
  
}

export const getPricingCardsApi = async(lang)=>{
  const requestOptions = {
    method: 'GET',
    redirect: 'follow'
  }
  const response = await fetch(`${baseURL}/site/default/pricing?lang=${lang}`, requestOptions)
  return response.text()
  
}

export const resetPasswordApi = async (data)=>{
  
  const formdata = new FormData();

  formdata.append("auth_key", data.auth_key);
  formdata.append("domain_name", data.domain_name);
  formdata.append("password", data.password);
  formdata.append("lang", data.lang);

  const requestOptions = {
    method: 'POST',
    headers: {
      "accept": "application/json"
    },
    body: formdata,
    redirect: 'follow'
  };
  
  const response = await fetch(`${baseURL}/auth/default/reset-password`, requestOptions)
  return response.text();

}



//CHAT BOT APIS


export const chatBotRoomListApi = async ()=>{
  
  const requestOptions = {
    method: 'GET',
    headers: {
      "accept": "application/json"
    },
    redirect: 'follow'
  };
  
  const response = await fetch(`${baseURL}/site/default/rooms-list`, requestOptions)
  return response.text();

}


export const getRoomOldMessagesApi = async({room_id})=>{
  const formdata = new FormData();
  formdata.append("room_id", room_id);

  const requestOptions = {
    method: 'POST',
    body: formdata,
    redirect: 'follow'
  };

  const response = await fetch(`${baseURL}/site/default/all-replies`, requestOptions)
  return response.text();
    
}


export const sendMessageToSarverApi = async({q,room_id})=>{
  
  const formdata = new FormData();
  formdata.append("room_id", room_id);
  formdata.append("content", q);
  
  const requestOptions = {
    method: 'POST',
    body: formdata,
    redirect: 'follow'
  };
  
  const response = await fetch(`${baseURL}/site/default/new-reply`, requestOptions)
  return response.text();
    

}


export const editClientMessage = async ({room_id = "", prompt_content})=>{
  const formdata = new FormData();
  if(room_id){
    formdata.append("room_id", room_id);
  }
  formdata.append("content", prompt_content);

  const requestOptions = {
    method: 'POST',
    body: formdata,
    redirect: 'follow'
  };

  const response = await fetch(`${baseURL}/site/default/update-prompt`, requestOptions)
  return response.text();
  
}


//CHAT BOT  V4 APIS


export const chatBotRoomListApiv4 = async ()=>{
  
  const requestOptions = {
    method: 'GET',
    headers: {
      "accept": "application/json"
    },
    redirect: 'follow'
  };
  
  const response = await fetch(`${baseURL}/site/default/rooms-list-v4`, requestOptions)
  return response.text();

}


export const getRoomOldMessagesApiv4 = async({room_id})=>{
  const formdata = new FormData();
  formdata.append("room_id", room_id);

  const requestOptions = {
    method: 'POST',
    body: formdata,
    redirect: 'follow'
  };

  const response = await fetch(`${baseURL}/site/default/all-replies-v4`, requestOptions)
  return response.text();
    
}


export const sendMessageToSarverApiv4 = async({q,room_id})=>{
  
  const formdata = new FormData();
  formdata.append("room_id", room_id);
  formdata.append("content", q);
  
  const requestOptions = {
    method: 'POST',
    body: formdata,
    redirect: 'follow'
  };
  
  const response = await fetch(`${baseURL}/site/default/new-reply-v4`, requestOptions)
  return response.text();
    

}


export const editClientMessagev4 = async ({room_id = "", prompt_content})=>{
  const formdata = new FormData();
  if(room_id){
    formdata.append("room_id", room_id);
  }
  formdata.append("content", prompt_content);

  const requestOptions = {
    method: 'POST',
    body: formdata,
    redirect: 'follow'
  };

  const response = await fetch(`${baseURL}/site/default/update-prompt-v4`, requestOptions)
  return response.text();
  
}

export const qulifiedApi = async (room_id = "")=>{
  const formdata = new FormData();
  
  
  if(room_id){
    formdata.append("room_id", room_id);
  }

  
  const requestOptions = {
    method: 'POST',
    body: formdata,
    redirect: 'follow'
  };
  
  const response = await fetch(`${baseURL}/site/default/qualified`, requestOptions)
  return response.text();
  
}

export const requestQulifiedApi = async (room_id = "")=>{
  const formdata = new FormData();
  
  
  if(room_id){
    formdata.append("room_id", room_id);
  }

  
  const requestOptions = {
    method: 'POST',
    body: formdata,
    redirect: 'follow'
  };
  
  const response = await fetch(`${baseURL}/site/default/qualification`, requestOptions)
  return response.text();
  
}
