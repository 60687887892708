import React from 'react'
import PhoneInput from 'react-phone-input-2'
import { useSelector } from 'react-redux';


function AppPhoneInput({showLabel = true ,placeholder = "" ,name = "",label, onChange, value, error, disabled=false}) {
  const {lookups} = useSelector(state => state.lookups);
  return (
    <div className="signInput">
      {(label && showLabel) && <label htmlFor="">{label || "Mobile"}</label>}
      <div className="signInput__inner">
        <div className = {"app-phone-input"}>
          <PhoneInput
            placeholder = {placeholder}
            country={`${lookups?.data?.isoCode}`.toLowerCase() || "jo"}
            value={value}
            name = {name}
            disabled = {disabled}
            onChange={onChange}
            inputProps = {{
              name: name
            }}
          />
        </div>
        {error? <div className = {"error__text"}>{error}</div> : null}
      </div>
    </div>
  )
}

export default AppPhoneInput
    // <div className="signInput">
    //     {lable && <label htmlFor="">{"Mobile"}</label>}
    //     <div className="signInput__inner">
    //         <div className="signSelect__select">
    //             <select 
    //                 className={"minimal"}
    //                 value={code}
    //                 onChange = {onCodePick}
    //             >
    //             {countriesCode.map(cc=>(<option key={cc.code} value={cc.dial_code}>{cc.flag}{cc.dial_code}</option>))}
    //             </select>
    //             <input placeholder={""} type="text" id="phone"  name="phone" value={value} onChange = {onChange}/>
    //         </div>
    //     </div>
    // </div>