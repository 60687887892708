import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getTranslation } from '../../apis'
import { AVAILABLE_LANG_SRT, LANGUAGE_TYPE } from '../../Helper/constants';
import { getCookie, setCookies } from '../../Helper/functions';

export const getTranslationHandler = createAsyncThunk('user/language',
  async ({lang,category},thunkAPI)=>{
    try {
      let res = await getTranslation(lang, category);
      res = JSON.parse(res)
      return res
    } catch (error) {
      // console.log(error)
      return thunkAPI.rejectWithValue("SERVER ERROR");
    }
  }
)

// Get user from Locl storage
// const language = localStorage.getItem("language");
const language = getCookie("language");

const param = window.location.pathname.split("/")[1]
const data  = param.split("-")
const lang = AVAILABLE_LANG_SRT.includes(data && data[0]?data[0].toLowerCase():"")? LANGUAGE_TYPE[data[0].toLowerCase()]: language === LANGUAGE_TYPE.ar? LANGUAGE_TYPE.ar: LANGUAGE_TYPE.en

const languageSlice = createSlice({
  name: 'language',
  initialState: {
    lang: lang,
    translations: [],
    page: "loginPage",
    status: null
  },
  reducers: {
    changeLanguage: (state, {payload})=>{
      state.lang = payload
    }
  },
  extraReducers: {
    [getTranslationHandler.pending]: (state, action)=>{
      state.status = "loading"
    },
    [getTranslationHandler.fulfilled]: (state, {payload})=>{
      state.status         = "success"
      state.translations   = {
        ...state.translations,
        [`${payload?.extra?.category}`]: payload.data
      }
      state.lang           = payload.extra.lang
      state.page           = payload?.extra?.category
    },
    [getTranslationHandler.rejected]: (state, action)=>{
      state.status = "failed"
    }
  },
})

// Action creators are generated for each case reducer function
export const { changeLanguage } = languageSlice.actions



export default languageSlice.reducer