import React, { useEffect, useState } from 'react'
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Footer from '../Base/Footer';
import Header from '../Base/Header';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Login from './Login'
import SignUp from './SignUp/SignUp'
import ForgotPassword from './ForgotPassword'
import { LinkedInCallback } from 'react-linkedin-login-oauth2';
import Page404 from './Page404';
import TwoStep from './Login/TwoStep'
import ResetPassword from './ResetPassword'
import { useDispatch, useSelector } from 'react-redux';
import { LANGUAGE_TYPE } from '../Helper/constants';
import { getCookie, setCookies } from '../Helper/functions';
import countriesCode from '../Helper/countriesCode';
import {Helmet} from "react-helmet";
import ChatBot from '../Components/ChatBot';
import ChatBotV4 from '../Components/ChatBotV4';
import Qulified from '../Components/Qulified';


const whitelist= ["forgot-password","reset-password", "chat", "chat-v4","qulified"]

export default function AuthStack() {

  const {lookups, error} = useSelector(state => state.lookups);
  const {lang} = useSelector(state => state.language);
  const dispatch = useDispatch()
  const location = useLocation();
  const country = getCookie("country");
  // const param = location.pathname.replace(/\//g, '')
  const param = location.pathname.split("/")[1]
  const data  = param.split("-")
  const _lang = LANGUAGE_TYPE.en === lang?"en":"ar"
  const navigate = useNavigate()
  
  const [langRoute, setLangRoute] = useState(`${_lang}-${(data && data[1])? data[1].toLowerCase(): country?country:""}`)
  
  
  
  useEffect(() => {
    if(error && !lookups?.data?.isoCode){
      if(country){
        const route = `${_lang}-${country}`
        setLangRoute(route)
        navigate(route,{replace: true})
      }
    }
  }, [error])
  

    // useEffect(() => {
      // if(lang && data[0]){
      //   setLangRoute(`${data[0].toLowerCase()}-${_lang}`)
      // }
    // }, [])

    useEffect(() => {
      // const _code = lookups?.data?.isoCode.toLowerCase()
      if(!countriesCode.includes(data[1])){
        const isoCode = lookups?.data?.isoCode.toLowerCase()
        if(isoCode){
          const route = `${_lang}-${isoCode}`
          setLangRoute(route)
          setCookies("country",isoCode,10000,`.${process.env.REACT_APP_DOMAIN}`)
          onNavigate(route)

        }
      }
      
    }, [lookups?.data?.isoCode])
    
    const onLangChange = (lang)=>{
      if(lang !== _lang){
        const data  = param.split("-")
        const route = `${lang}-${data[1]}`
        const endpoint = location.pathname.indexOf("/", 1) > -1?location.pathname.slice(location.pathname.indexOf("/", 1)): ""
        // setLangRoute(route)
        // onNavigate(route + endpoint)
        window.location.replace(`/${route + endpoint}`)
      }
      // window.location.replace(`${data[0]}-${lang}`)
    }
    

    const onNavigate = (path)=>{
      
      if(whitelist.includes(param)){
        // console.log(window.location.href)
        // navigate(param)
      }else{
        navigate(path)
      }
      
    }

    console.log(param)
 

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <Header onLangChange = {onLangChange} toRoute = {`${langRoute}`} />
        {
          lookups?.data?.metaTags && lookups?.data?.metaTags[langRoute]?
          <Helmet>
            {
              lookups?.data?.metaTags[langRoute]?.keywords?
                <meta name="keywords" content={`${lookups?.data.metaTags[langRoute].keywords || ""}`}></meta>
              :
              null
            }
            {lookups?.data?.metaTags[langRoute]?.title?
              <title>{`${lookups?.data.metaTags[langRoute].title || ""}`}</title>
              :
              null
            }
          </Helmet>
          :
          <></>
        }
        
        <div className='main'>
        {
            langRoute?
            <Routes>
              <Route path="*" element={<Page404 />} />
              
              <Route path={`${langRoute}`}>
                <Route index element={<Login toRoute = {`${langRoute}`}/>} />
                <Route path="login" element={<Login toRoute = {`${langRoute}`}/>} />
                <Route path="login/varification" element={<TwoStep toRoute = {`${langRoute}`}/>} />
                <Route path="signup">
                  <Route index path='' element = {<SignUp toRoute = {`${langRoute}`}/>} />
                  <Route path=':signupStep' element = {<SignUp toRoute = {`${langRoute}`} />} />
                </Route>
                <Route path="reset-password" element={<ResetPassword toRoute = {`${langRoute}`}/>} />
                <Route path="forgot-password" element={<ForgotPassword toRoute = {`${langRoute}`}/>} />
              </Route>
              
              <Route path="chat" element={<ChatBot toRoute = {`${langRoute}`}/>} />
              <Route path="chat-v4" element={<ChatBotV4 toRoute = {`${langRoute}`}/>} />
              <Route path="qulified/:room_id" element={<Qulified toRoute = {`${langRoute}`}/>} />
              <Route path="reset-password" element={<ResetPassword toRoute = {`${langRoute}`}/>} />
              <Route path="forgot-password" element={<ForgotPassword toRoute = {`${langRoute}`}/>} />
              <Route path="linkedin" element={<LinkedInCallback/>} />
              <Route path="/" element={<Navigate to={`${langRoute}`} replace />}/>
            </Routes>
          :
          <div className='sign'></div>
        }
        </div>
        {
          param !== "chat" && param !== "qulified" && param !== "chat-v4"?
            <Footer toRoute = {`${langRoute}`} />
          :
          <></>
        }
    </GoogleOAuthProvider>
  )
}

// const Init = ()=>{
//   const navigate = useNavigate();
//   useEffect(() => {
//     navigate("/login",{replace: true})
//   }, [])
//   return <Login />
// }


// const SignUpHeader = ()=>(
//   hea
// )