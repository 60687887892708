import React, { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import AppButton from "../../../../Components/AppButton";
// import ReactLoading from 'react-loading';
import Loader from '../../../../Components/Loader'
import AppInput from "../../../../Components/AppInput";
import { mixpanelActions } from "../../../../Helper/constants";
import mixpanel from "mixpanel-browser";
// import { checkEnvironmentStatus } from "../../../../apis";
// import { statusCode } from "../../../../Helper/constants";

export default function Step7({timer = 0,isLoading = false,errors,list, submitForm,state,setState}) {
// export default function Step7({errors,list, submitForm,state,setState}) {
  // const isLoading = true
  // const timer = 50

  

  const {translations, lang} = useSelector(state => state.language);
  const commonTranslation = translations?.common || {};

  const [selected, setSelected] = useState({
    type: "",
    value: ""
  })


  return (
    <>
    
        {
          isLoading?
            // <div style={{minHeight: 300, alignItems: 'center', display: "flex"}}>
            <>
              <div className={`loader-data ${isLoading && timer < 10? "active": ""}`}>
                <div class= "loader-container">
                  <Loader/>
                </div>
              </div>
              <div className={`loader-data ${timer >= 10 && timer < 20? "active": ""}`}>
                <div class= "loader-container">
                    <Loader type = {"icon1"} />
                </div>
                <div className="loader-text">
                  <div>{commonTranslation["common.define_sales"] || "Define your sales process"}</div>
                  <p>{commonTranslation["common.define_sales_feature"] || "Before implementing a sales automation platform, make sure you have a clear understanding of your sales process. Determine the key stages and steps involved, and map them out within the platform. This will help ensure that the platform is aligned with your sales strategy and will allow you to effectively manage leads and opportunities."}</p>
                </div>
              </div>
              <div className={`loader-data ${timer >= 20 && timer < 30? "active": ""}`}>
                  <div class= "loader-container">
                      <Loader type = {"icon2"} />
                  </div>
                  <div className="loader-text">
                    <div>{commonTranslation["common.track_and_analyze"] || "Track And Analyze Your Data"}</div>
                    <p>{commonTranslation["common.track_and_analyze_feature"] || "Make sure you're regularly tracking and analyzing your data within the platform. This will help you identify areas for improvement, measure the effectiveness of your sales process, and make data-driven decisions. Use the insights gained from your data to optimize your workflows and improve your overall sales performance."}</p>
                  </div>
              </div>
              <div className={`loader-data ${timer >= 30 && timer < 40? "active": ""}`}>
                <div class= "loader-container">
                    <Loader type = {"icon3"} />
                </div>
                <div className="loader-text">
                  <div>{commonTranslation["common.use_automation_wisely"] || "Use Automation Wisely"}</div>
                  <p>{ commonTranslation["common.use_automation_wisely_feature"] || "Automation can be a powerful tool, but it's important to use it wisely. Automate repetitive tasks such as follow-up emails, reminders, and scheduling, but don't rely on automation for every interaction. Remember that personalization is key in sales, so make sure you're still taking the time to craft personalized messages and build relationships with your prospects."}</p>
                </div>
              </div>
              <div className={`loader-data ${timer >= 40 && timer < 50? "active": ""}`}>
                <div class= "loader-container">
                    <Loader type = {"icon4"} />
                </div>
                <div className="loader-text">
                  <div>{commonTranslation["common.utilize_integration"] || "Utilize Integrations"}</div>
                  <p>{commonTranslation["common.utilize_integration_feature"] || "Nunmu offers integrations with other tools such as accounting software, email marketing software, and social media platforms. Take advantage of these integrations to improve your workflow and efficiency. Integrating your sales automation platform with your accounting software, for example, can help you keep track of invoices, payments, and other financial data."}</p>
                </div>
              </div>
              <div className={`loader-data ${timer >= 50? "active": ""}`}>
                  <div style={{minHeight: 300, alignItems: 'center', display: "flex", margin: 'auto'}}>
                    <div>{commonTranslation["common.setup_takes_to_much_time"] || "Your Setup takes to much time, We will inform you via email."}</div>
                  </div>
              </div>
            </>
            // </div>
          :
          <>
            <div className="report-cards-container">
              {
                list.map(item=>(
                  <div class={`report-card clickable ${selected.type === item.value? "active": ""}`} onClick = {()=>{
                      const m_e = mixpanelActions.INDUSTRY_ON_SELECT(lang,item.value)
                      mixpanel.track(m_e.event, m_e.properties);
                      setSelected({type: item.value, value: ""})
                  }}>
                    <div class="report-card-added-icon"><svg width="20" height="21" viewBox="0 0 20 21" fill="none"><path d="M4.16675 10.9937L7.50008 14.327L15.8334 5.99365" stroke="#14B8A6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg></div>
                      <div class="report-card-image">
                        <img src={item.image}/>
                      </div>
                      <div class="report-card-body">
                        <h3>{item.label}</h3>
                        <p>{item.description}</p>
                      </div>
                  </div>
                ))
              }
            </div>
            {
              selected.type === "Others"?
              <div className="signInput">
                  <label htmlFor="">{commonTranslation["common.others"] || "Others  (Industry other than mentioned)"}</label>
                  <AppInput
                    placeholder={commonTranslation["common.select_industry"] || "Select Industry"}
                    onChange = {(e)=>{
                      setSelected({type: "Others", value: e.target.value})
                    }}
                  />
                  {errors?.other_industry? <div className = {"error__text"}>{errors?.other_industry}</div> : null}
              </div>
              :
              <></>
            }
            <AppButton
              title={commonTranslation["common.get_started"] || "Get Started"}
              isLoading = {isLoading}
              onClick={() => submitForm(selected)}
              style = {{width: "100%"}}
            />
          </>
      }
    </>
  );
}
