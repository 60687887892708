import React, { useState, useRef } from "react";

export default function SplitInput({onComplete}) {
  const [val, setVal] = useState({
    num1: "",
    num2: "",
    num3: "",
    num4: "",
    num5: "",
    num6: "",
  });
  const updateVal = (data) => {
    setVal({ ...val, ...data });
  };

  const input1 = useRef(null);
  const input2 = useRef(null);
  const input3 = useRef(null);
  const input4 = useRef(null);
  const input5 = useRef(null);
  const input6 = useRef(null);

  const setValN = (n, e) => {
    const value = e.target.value;
    if (value?.length > 1) {
      return;
    }
    if (n === 1) {
      updateVal({ num1: value });
      if (value !== "") input2.current.focus();
    } else if (n === 2) {
      updateVal({ num2: value });
      if (value === "") input1.current.focus();
      else input3.current.focus();
    } else if (n === 3) {
      updateVal({ num3: value });
      if (value === "") input2.current.focus();
      else input4.current.focus();
    } else if (n === 4) {
     
      updateVal({ num4: value });
      if (value === "") input3.current.focus();
      else input5.current.focus();
    } 
    else if (n === 5) {
      updateVal({ num5: value });
      if (value === "") input4.current.focus();
      else input6.current.focus();
    } else if (n === 6) {
      const code = val.num1+val.num2+val.num3+val.num4+val.num5+value
      onComplete(code)
      updateVal({ num6: value });
      if (value === "") input5.current.focus();
    }
  };

  return (
    
      <div className="splitInput">
        
        <div className="splitInput__input">
          <label htmlFor=""></label>
          <input
            ref={input1}
            type="number"
            min="1"
            max="999"
            placeholder=""
            value={val.num1}
            onChange={(e) => setValN(1, e)}
          />
        </div>
        <div className="splitInput__input">
          <label htmlFor=""></label>
          <input
            ref={input2}
            type="number"
            min="1"
            max="999"
            placeholder=""
            value={val.num2}
            onChange={(e) => setValN(2, e)}
          />
        </div>
        <div className="splitInput__input">
          <label htmlFor=""></label>
          <input
            ref={input3}
            type="number"
            min="1"
            max="999"
            placeholder=""
            value={val.num3}
            onChange={(e) => setValN(3, e)}
          />
        </div>
        <div className="splitInput__input">
          <label htmlFor=""></label>
          <input
            ref={input4}
            type="number"
            min="1"
            max="999"
            placeholder=""
            value={val.num4}
            onChange={(e) => setValN(4, e)}
          />
        </div>
        <div className="splitInput__input">
          <label htmlFor=""></label>
          <input
            ref={input5}
            type="number"
            min="1"
            max="999"
            placeholder=""
            value={val.num5}
            onChange={(e) => setValN(5, e)}
          />
        </div>
        <div className="splitInput__input">
          <label htmlFor=""></label>
          <input
            ref={input6}
            type="number"
            min="1"
            max="999"
            placeholder=""
            value={val.num6}
            onChange={(e) => setValN(6, e)}
          />
        </div>
      </div>
  );
}
