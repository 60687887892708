import React from 'react'

function Progressbar({steps,activeStep = 1,showLabels = false}) {
  return (
    <div className='progressbar-container'>
        {
            steps.map((step, index)=>(
                <div className={`progressbar-step ${index+1 <= activeStep?"active":""}`}>
                  {
                    showLabels?
                    <div className='progressbar-step-title'><span>{index+1}</span>{step}</div>
                    :
                    <></>
                  }
                </div>
            ))
        }
        
    </div>
  )
}

export default Progressbar