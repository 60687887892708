import React, { useEffect, useState } from "react";


import { useDispatch, useSelector } from "react-redux";
import queryString from 'query-string'

import { useNavigate, useRoutes } from "react-router";
// import { passwordResetHandler } from "../../redux/features/auth/authSlice";
import { checkPasswordStrengthApi, resetPasswordApi } from "../../apis";
import { statusCode } from "../../Helper/constants";
import PasswordStrengthBar from 'react-password-strength-bar';
import { toast } from 'react-toastify';
import AppButton from "../../Components/AppButton";



const ResetPassword = ({toRoute})=>{


  const {lang, translations} = useSelector(state => state.language);
  
  const navigate  = useNavigate();

  const parsed = queryString.parse(window.location.search);
  const loginPageTranslation = translations?.loginPage || {};
  const commonTranslation = translations?.common || {};
  const [errorsOnChange, setErrorsOnChange] = useState([])


  /**
   * State Management
   */
  const [state, setState] = useState({
    email: "", 
    reCaptcha: "",
    reset: false,
    password: "",
    isLoading: false,
    errors: {}
  })

  
  useEffect(() => {
    // resetPasswordHandler();
  }, [])
  

  

  /**
   * Request email for submit
   */

  
  const resetPasswordHandler = async ()=>{
      
    if(errorsOnChange?.password?.length > 0){
        return
    }

    const data  = {
      auth_key: parsed?.token || "",
      domain_name: parsed?.domain_name || "",
      password: state.password,
      lang: lang
    }
    

    try {
        setState({...state, isLoading: true})
      let res = await resetPasswordApi(data)
      setState({...state, isLoading: false})
      res = JSON.parse(res);
      if(res.code == statusCode.K200){
        
        toast.success(res?.data?.password || "Something went wrong with social login")
        setState({
          email: "", 
          reCaptcha: "",
          reset: false,
          password: "",
        })
        setErrorsOnChange([])
        navigate(`/${toRoute}/login`, { replace: true })
        
      }
      if(res.code == statusCode.K422){
        if(res?.errors?.token){
            toast.error(res?.errors?.token || "Something went wrong")
            navigate(`/${toRoute}/login`, { replace: true })
        }else{
        toast.error(res?.errors?.password || "Something went wrong")
        }  
      }
    } catch (error) {
        setState({...state, isLoading: false})
      console.log(error)
    }

  }



  /**
   * Check Password Strength From Backend
   */
   const checkPasswordStrengthHander = async(e)=>{
     
     const password = e.target.value
     setState({...state,password: password});
     try {
       let res = await checkPasswordStrengthApi(password, lang);  
       res  = JSON.parse(res);
       
       if(res.code == statusCode.K422){
        setErrorsOnChange(res?.errors)
        }else{
          setErrorsOnChange([])
         
       }
       
     } catch (error) {
       console.log(error)
     }

  }


  /**
   * Entry Point
   */
  return (
    <div className="sign">      
        <div className="sign__inner">
          <div className="signTitle">{commonTranslation["common.change_password"] || "Change Password"}</div>
              
              {/* <div className="signInput">
                <label htmlFor="">{loginPageTranslation["loginPage.email_address"]}</label>
                <div className="signInput__inner">
                  <input  placeholder="mail@site.com" type="text" id="email" name="email" value={state.email} onChange = {(e)=>{setState({...state,email: e.target.value})}}/> 
                </div>
                {errors.email? <span className = {"error__text"}>{errors.email}</span> : null}
              </div> */}

              
                <div className="signInput" style={{marginTop: 10}}>
                  <label htmlFor="">{commonTranslation["common.password"] || "Password"}</label>
                  <div className="signInput__inner">
                    <input placeholder="Password" type="password" id="password" name="password" value={state.password} onChange = {(e)=>{checkPasswordStrengthHander(e);}}/> 
                  </div>
                  <div style={{marginTop: 15, marginBottom: 5, display: 'flex'}}>
                    <label style={{marginBottom: 0, marginRight: 10}}>{commonTranslation["common.password_strength"] || "Password Strength"}</label>
                    <div style={{flex: 1}}>
                      <PasswordStrengthBar password={state.password} scoreWords = {[]} shortScoreWord = {""}/>
                    </div>
                  </div>

                  {
                    errorsOnChange.password && state?.password?.length > 0? 
                      <>
                        {
                          errorsOnChange.password.map((p,index)=>(
                            <p style={{margin: "5px 0"}} key={index} className = {"error__text"}>{p}</p>
                          ))
                        }
                      </>
                      : 
                      <label style={{marginTop: 10}}>{commonTranslation["common.hint_password"] || "- Add 8 characters or more, lowercase letters, uppercase letters, numbers and symbols to make the password strong"}</label>
                  }
                </div>
                <AppButton
                  isLoading = {state.isLoading}
                  title = {commonTranslation["common.proceed"] || "Proceed"}
                  onClick = {resetPasswordHandler}
                  style={{width: "100%"}}
                />
        </div>
    </div>
  );
}

export default ResetPassword