export default [
  "af", "al", "dz", "ad", "ao", "ag", "ar", "am", "au", "at", "az", "bs", "bh", "bd", "bb",
  "by", "be", "bz", "bj", "bt", "bo", "ba", "bw", "br", "bn", "bg", "bf", "bi", "cv", "kh",
  "cm", "ca", "cf", "td", "cl", "cn", "co", "km", "cg", "cd", "cr", "hr", "cu", "cy", "cz",
  "dk", "dj", "dm", "do", "tl", "ec", "eg", "sv", "gq", "er", "ee", "et", "fj", "fi", "fr",
  "ga", "gm", "ge", "de", "gh", "gr", "gd", "gt", "gn", "gw", "gy", "ht", "hn", "hu", "is",
  "in", "id", "ir", "iq", "ie", "il", "it", "ci", "jm", "jp", "jo", "kz", "ke", "ki", "kp",
  "kr", "kw", "kg", "la", "lv", "lb", "ls", "lr", "ly", "li", "lt", "lu", "mk", "mg", "mw",
  "my", "mv", "ml", "mt", "mh", "mr", "mu", "mx", "fm", "md", "mc", "mn", "me", "ma", "mz",
  "mm", "na", "nr", "np", "nl", "nz", "ni", "ne", "ng", "no", "om", "pk", "pw", "pa", "pg",
  "py", "pe", "ph", "pl", "pt", "qa", "ro", "ru", "rw", "kn", "lc", "vc", "ws", "sm", "st",
  "sa", "sn", "rs", "sc", "sl", "sg", "sk", "si", "sb", "so", "za", "ss", "es", "lk", "sd",
  "sr", "sz", "se", "ch", "sy", "tw", "tj", "tz", "th", "tg", "to", "tt", "tn", "tr", "tm",
  "ug", "ua", "ae", "gb", "us", "uy", "uz", "vu", "va", "ve", "vn", "ye", "zm", "zw"
];