import {useState, useEffect, useCallback} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { MyAccessTokenVar, MyRefreshTokenVar, SubDomain } from '../Helper/constants';
import {removeCookie, getCookie, setCookies} from '../Helper/functions'
// import { updateTokens } from '../../redux/features/auth/authSlice';

function useTokens() {
    // const {access_token,refresh_token} = useSelector(state=> state.auth);
    const {access_token,refresh_token} = {}
    const dispatch  = useDispatch();
    

    const getAccessToken = ()=>{
        const readCookie = getCookie(MyAccessTokenVar)
        return readCookie === access_token? access_token:readCookie
    }
    
    const getRefreshToken = async ()=>{
        const readCookie = getCookie(MyRefreshTokenVar)
        return readCookie === refresh_token? refresh_token:readCookie
    }
  
    const setAccessToken = (value,domain = false)=>{

        const res = setCookies(MyAccessTokenVar, value,100000,domain)
        if(res){
            return true
            // dispatch(updateTokens({accessToken: res}))
            // setAccessTokenState(res)
        }else {
            return false
        }
    }
    const getSubdomainToken = ()=>{
        const readCookie = getCookie(SubDomain)
        return readCookie === access_token? access_token:readCookie
    }
    
    const setDomainName = (value,domain = false)=>{
        const res = setCookies(SubDomain, value,100000,domain)
        if(res){
            return true
            // dispatch(updateTokens({accessToken: res}))
            // setAccessTokenState(res)
        }else {
            return false
        }
    }
    
    const setRefreshToken = (value,op)=>{
        return
        const res = setCookies(MyRefreshTokenVar, value,100000)
        if(res){
            // dispatch(updateTokens({refreshToken: res}))
        }
    
    }

    const removeCookies = ()=>{
        const res = removeCookie(MyAccessTokenVar);
        console.log(res, "removeCookies")
        if(res){
            // dispatch(updateTokens({
            //     accessToken: null,
            //     refreshToken: null
            // }))
        }
        // removeCookie("accessToken","",{
        //     path:"/"
        // })
        
    }

    const setTokens = async(at,rt)=>{
        const res = await setCookies(MyAccessTokenVar, at,100000)
        const res1 = await setCookies(MyRefreshTokenVar, rt,100000)
        if(res){
            return true
            // dispatch(updateTokens({
            //     accessToken: res,
            //     refreshToken: rt
            // }))
            // setAccessTokenState(res)
        }else{
            console.log("SET TOKENS HAVE ERROR USE TOKENS HOOK")
            return false
        }
    }

    // console.log(accessTokenState,"MY ACCESS TOKEN")

    return {
        accessToken: getAccessToken(),
        subdomain: getSubdomainToken(),
        refreshToken: getRefreshToken(),
        setTokens: setTokens,
        setAccessToken: setAccessToken,
        setDomainName: setDomainName,
        setRefreshToken: setRefreshToken,
        removeCookies: removeCookies

    }
}

export default useTokens