import React, { useEffect, useRef, useState } from "react";

import ReactLoading from 'react-loading';
import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router";
import ReCAPTCHA from "react-google-recaptcha";
// import { passwordResetHandler, reset } from "../../redux/features/auth/authSlice";
import { toast } from 'react-toastify';
import AppButton from "../../Components/AppButton";
import { passwordResetApi } from "../../apis";
import { statusCode } from "../../Helper/constants";



const ForgotPassword = ({errors = {}})=>{

  const {lang, translations} = useSelector(state => state.language);
  // const {isLoading, message, user, isSuccess, token, errors} = useSelector(state => state.auth)
  
  const dispatch  = useDispatch();
  const navigate  = useNavigate();
  

  const reCaptchaRef = useRef(null)
  const [errorsOnChange, setErrorsOnChange] = useState([])

  
  const commonTranslation = translations?.common || {};


  /**
   * State Management
   */
  const [state, setState] = useState({
    email: "", 
    reCaptcha: "",
    reset: false,
    password: "",
    isLoading: false
  })

  
  
  // useEffect(() => {

  //   if(isSuccess == "forgot-pass"){
  //     toast.success(message,{
  //       position: toast.POSITION.TOP_RIGHT,
  //       hideProgressBar: true
  //     })
  //     dispatch(reset())
  //     // setState({...state,reset: true})
  //     navigate("/login", { replace: true })
  //   }
  // }, [isSuccess])

  useEffect(() => {
    // dispatch(reset())
  }, [lang])
  

  /**
   * Request email for submit
   */
  const submitForm = async ()=>{
    try {
      setState({...state, isLoading: true})
      let recaptchaValue = await reCaptchaRef?.current?.executeAsync();
      
      const data = {email: state.email, reCaptcha: recaptchaValue, lang}
      let res = await passwordResetApi(data)
      res = JSON.parse(res)
      
      if(res?.data?.email){
        toast.success(res?.data?.email || "Something went wrong with social login")
      }
      else if(res.errors){
        toast.error(res?.errors?.message || "Something went wrong with social login")
        
      }

      setState({...state, isLoading: false})
      reCaptchaRef?.current?.reset()
  
    } catch (error) {
      setState({...state, isLoading: false})
      console.log(error,"___")
    }
    // dispatch(passwordResetHandler(data))
    
  }


  /**
   * Entry Point
   */
  return (
    <div className="sign">      
        <div className="sign__inner">
          <div className="signTitle">{commonTranslation["common.forgot_password"]}</div>
              
              <div className="signInput" style={{marginTop: 20}}>
                <label htmlFor="">{commonTranslation["common.email_address"]}</label>
                <div className="signInput__inner">
                  <input  placeholder={commonTranslation["common.email_address"]} type="text" id="email" name="email" value={state.email} onChange = {(e)=>{setState({...state,email: e.target.value})}}/> 
                </div>
                {errors.email? <span className = {"error__text"}>{errors.email}</span> : null}
              </div>

              
              <div className="signCheck" style={{marginTop: 10}}>
                <ReCAPTCHA
                  ref = {reCaptchaRef}
                  sitekey={process.env.REACT_APP_RECAPTCHA_CLIENT_ID}
                  // onChange={(value)=>setState({...state,reCaptcha: value})}
                  size = {"invisible"}
                  onError = {(e)=>{console.log(e)}}
                />
                {errors.reCaptcha? <span className = {"error__text"}>{errors.reCaptcha}</span> : null}
              </div>
              <AppButton onClick = {submitForm} title= {commonTranslation["common.proceed"]} style={{width: "100%"}} isLoading = {state.isLoading}/>
         
        </div>
    </div>
  );
}

export default ForgotPassword