import React from 'react'
import { loaderSvg } from '../../assets/svgs'
import Lottie from "lottie-react";
import LoaderAnimation from '../../assets/139754-block-loading.json'
import WorkAnimation from '../../assets/work.json'
import Icon1 from '../../assets/loading/icon1.json'
import Icon2 from '../../assets/loading/icon2.json'
import Icon3 from '../../assets/loading/icon3.json'
import Icon4 from '../../assets/loading/icon4.json'
// import Icon5 from '../../assets/loading/icon5.json'
// import Icon5Long from '../../assets/loading/icon_long5.json'

export default function Loader({type = ""}) {
  return (
    <div className='loader-container'>
      {/* <div className='loader-spin'> */}
        {/* {loaderSvg} */}
        {
          type === "icon1"?
          <Lottie  animationData={Icon1} loop={true} style={{height: 150}}/>
          :
          type === "icon2"?
          <Lottie  animationData={Icon2} loop={true} style={{height: 150}}/>
          :
          type === "icon3"?
          <Lottie  animationData={Icon3} loop={true} style={{height: 150}}/>
          :
          type === "icon4"?
          <Lottie  animationData={Icon4} loop={true} style={{height: 150}}/>
          :
          // type === "icon5"?
          // <Lottie  animationData={Icon5} loop={true} style={{height: 150}}/>
          // :
          type === "work"?
          <Lottie  animationData={WorkAnimation} loop={true} style={{height: 150}}/>
          :
          <Lottie  animationData={LoaderAnimation} loop={true} style={{height: 150}}/>
        }
      {/* </div> */}
    </div>
  )
}
