import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { utilitiesLookupApi } from '../../apis'
import { statusCode } from '../../Helper/constants';


export const getUtalityLookups = createAsyncThunk('user/lookups',async (data,thunkAPI)=>{
    try {
      let res = await utilitiesLookupApi();
      res = JSON.parse(res)
      if(res.code === statusCode.K200){
          return res
      }else{
        return thunkAPI.rejectWithValue(res.errors);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue("SERVER ERROR");
    }
  }
)


const lookupSlice = createSlice({
  name: 'lookup',
  initialState: {
    isLoading: false,
    lookups: {},
    commonData: {},
    error: null,
    isSuccess: false
  },
  
  extraReducers: {
    [getUtalityLookups.pending]: (state, action)=>{
      state.isLoading = true
    },
    [getUtalityLookups.fulfilled]: (state, {payload})=>{
        state.isLoading = false
        state.lookups = payload
        state.isSuccess = true
        state.error = null
      
    },
    [getUtalityLookups.rejected]: (state, {payload})=>{
        state.isLoading = false
        state.lookups = {}
        state.isSuccess = false
        state.error = payload
    },
  },
})



export default lookupSlice.reducer