import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SplitInput from "./SplitInput";
import ReactLoading from 'react-loading';
import { useLocation, useNavigate } from "react-router";
import { getTranslationHandler } from "../../../redux/features/language";
import AppButton from "../../../Components/AppButton";
import { resendTFAapi, twoStepVarificationApi } from "../../../apis";
import { MyAccessTokenVar, MyRefreshTokenVar, statusCode, SubDomain } from "../../../Helper/constants";
import { toast } from "react-toastify";
import { setCookies } from "../../../Helper/functions";
// import useTokens from "../../../Dashboard/Hooks/useTokens";

export default function TwoStep({toRoute}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  
  const [state, setState] = useState({
    extra: location?.state,
    isLoading: false
  })

  const {lang, translations} = useSelector(state => state.language);
  const commonTranslation = translations?.common || {};
  // const {isLoading, isError, isSuccess,errors, user, token} = useSelector(state => state.auth);
  const signupPageTranslation = translations?.signupPage || {};
  // const [cookies, setCookie, removeCookie] = useCookies(['accessToken']);
  // const {setAccessToken} = useTokens()
  
  const [code, setCode] = useState("");
  const [error, setError] = useState(false);

  useEffect(() => {
    
    setState({
      ...state,
      extra: location?.state,
    })
    
  }, [location?.state])
  

  
  // useEffect(() => {
  //   if(errors?.length > 0){
  //     if(errors?.two_factor_authentication)return
      
  //     setError(errors)
  //   }
  //   if(!isSuccess){
    
  //   }
    
  //   if(isSuccess === "resent"){
  //     toast.success(errors?.two_factor_authentication || "Success sent.");
  //   }

  //   if(isSuccess === "twoFactorDone"){
  //     if(user?.data?.access_token){
  //       // removeCookie("accessToken","", {path: "/"})
  //       setAccessToken(user.data.access_token,{
  //         expires: new Date(2050,0,1),
  //         path:"/"
  //       })
  //       // setCookie("accessToken", user.data.access_token,{
  //       //   expires: new Date(2050,0,1),
  //       //   path:"/"
  //       // })
  //       // navigate("/dashboard")
  //     }
      
  //     // setCookie("accessToken",user?.data?.access_token,{
  //     //   domain: `.${BASEURL}`
  //     // })
  //     // const domainName = user?.data?.domain_name || ""
  //     // window.location.assign(`https://${domainName}.${BASEURL}/dashboard`);

      
  //   }
  //   if(isError){
  //     // alert("someting went wrong")
  //   }

  //   window.scrollTo(0, 0);
  // }, [isSuccess, user, errors, isError]);
  

  useEffect(() => {
    getTranslation()
  }, [])
  

  /**
   * getTranslations
   */
   const getTranslation = ()=>{
    dispatch(getTranslationHandler({
      category:  'signupPage',
      lang: lang
    }))
  }

  const redirectUserToDomain = (res)=>{
    const _res = setCookies(MyAccessTokenVar, res.data.access_token,100000,`.${process.env.REACT_APP_DOMAIN}`)
    if(res?.data?.key){
      const _res2 = setCookies(MyRefreshTokenVar, res.data.access_token,100000,`.${process.env.REACT_APP_DOMAIN}`)
    }
    const _res1 = setCookies(SubDomain, res.data.domain_name,100000,`.${process.env.REACT_APP_DOMAIN}`)
    window.location.replace(`https://${res.data.domain_name}.${process.env.REACT_APP_DOMAIN}/dashboard`);
}


  /**
   * Submit Code for Verification
   */

  const submitCode = async()=>{
    
    // if(code?.length < 6){
    //   setError("Fill code")
    //   return
    // }
    
      try {
        let res = await twoStepVarificationApi({code, domain_name: state?.extra?.domain_name})
        res = JSON.parse(res)
        if(res.code === statusCode.K200){
          redirectUserToDomain(res)
        }else{
          toast.error(res?.errors?.message || "");
        }
      } catch (error) {
        console.log(error,"__")
      }
      // dispatch(twoStepVarification({code})) 
    }
    
    const resendCodeHandler = async ()=>{
      try {
        let res = await resendTFAapi(state?.extra?.auth_key, state?.extra?.domain_name)
        res = JSON.parse(res)
        if(res.code === statusCode.K200){
          setState({...state, isLoading: false, extra:{...res.extra, domain_name: state.extra.domain_name}})
          toast.success(res?.errors?.two_factor_authentication || "");
        }
      } catch (error) {
        setState({...state, isLoading: false})
      }
    
    // dispatch(resendCode(token))
  }

  return (
    <div className="sign">
      <div className="auto__container">
        <div className="sign__inner">
          <div className="signTitle">{signupPageTranslation["signupPage.authentication"]}</div>
          <div className="signText">{signupPageTranslation["signupPage.signup_heading"]}</div>
          <div className="signHeader">
            <div className="signHeader__step active">
              <div className="signHeader__step-number">1</div>
              <span>{commonTranslation["common.details"]}</span>
            </div>
            <hr />
            <div className="signHeader__step active">
              <div className="signHeader__step-number">2</div>
              <span>{signupPageTranslation["signupPage.authentication"]}</span>
            </div>
          </div>
          <div className="signSubtitle">{signupPageTranslation["signupPage.enter_authenticatoin_code"]}</div>
          <div className="signText">{signupPageTranslation["signupPage.signup_heading"]}</div>
          <SplitInput onComplete = {(code)=>{setCode(code);setError("")}} error = {error}/>
          <div style={{marginBottom: 24}}>
            {error !== false ? <span className = {"error__text"}>{error}</span> : null}
          </div>

          <span className="signText" style={{cursor: "pointer"}} onClick= {resendCodeHandler}>{signupPageTranslation["signupPage.resend_code"]}</span>
          
          
          <AppButton
            isLoading = {state.isLoading}
            title = {signupPageTranslation["signupPage.continue"]}
            onClick={submitCode}
            style = {{width: "100%"}}
          />
          

        </div>
      </div>
    </div>
  );
}
