import moment from "moment"

const BASEURL = window.location.hostname === "localhost:3000"?"localhost:3000": process.env.REACT_APP_URL

export const CUSTOMIZE_FIELDS_LENGTH = 5

const fileTypes = ["text/csv","text/xls","text/xlsx","application/vnd.openxmlformats-officedocument.spreadsheetml.sheet","application/vnd.ms-excel"]
const statusCode = {
    S400: "S400",
    K200: "N200",
    K201: "N201",
    K422: "N422", 
    K401: "N401",
    K409: "N409",
    K455: "N455",
    K404: "N404",
    K403: "N403",
    K406: "N406", // Subscriptio Expired ERROR
    K402: "N402", // UNPAID Expired ERROR
}
const AVAILABLE_LANG = ["English", "Arabic"];
export const AVAILABLE_LANG_SRT = ["en", "ar"];
const LANGUAGE_TYPE = {
    en : "en-US",
    ar : "ar-AR"
}

const staticPageSlug = ["about","contact","benefits", "terms-of-service","privacy-policy"]


const mapLib = ["drawing","places"]
const tablePerPage = 5

const MyRefreshTokenVar = "NU_C_023361dd35530_RF"
const MyAccessTokenVar = "NU_C_03553023361dd_TK"
const SubDomain = "NU_C_03553023361EE_SBD"

// const editorButtons = ['bold', 'italic',"ul","ol","undo","redo", "fontsize","center", "left", "right", "justify", "image"]
const editorButtons = ['bold', 'italic',"ul","ol","undo","redo", "fontsize","align", "image"]
const editorButtons1 = ['bold', 'italic',"ul","ol","undo","redo","fontsize","align"]


const colors = {
    skyBlue: "#25CAD3",
    orange: "#D97706",
    green: "#0D9488",
    color: "#E11D48",
    gray: "#374151",
    blue: "#3581BD",
    red: "rgb(225, 29, 72)",
    text: "#344054"
}

const months = {
    "January": 1, 
    "February": 2, 
    "March": 3, 
    "April": 4, 
    "May": 5, 
    "June": 6, 
    "July": 7,
    "August": 8,
    "September": 9, 
    "October": 10, 
    "November": 11,
    "December": 12
}
const quaters = {
    Q1:"Q1",
    Q2:"Q2",
    Q3:"Q3",
    Q4:"Q4"
}
const currentYear = moment().year()

const mixpanelActions = {
    SIGN_UP_SS: (lang, type)=>({
      "event": "sign up with google or linked in clicked",
      "properties": {
        "language": lang,
        "type": type
      },
    }),
    SIGN_UP_EMAIL: (lang)=>({
      "event": "sign up with email clicked",
      "properties": {
        "language": lang
      },
    }),
    GENETAL_INFO_ON_COMPLETE: (lang)=>({
      "event": "general info on completed",
      "properties": {
        "language": lang
      },
    }),
    
    MOBILE_NUMBER_AND_COMANY_ON_SUCCESS: (lang)=>({
      "event": "mobile number and company name completed",
      "properties": {
        "language": lang
      },
    }),
    DOMAIN_NAME_ON_SUCCESS: (lang)=>({
      "event": "domain name completed",
      "properties": {
        "language": lang
      },
    }),
    INDUSTRY_ON_SELECT: (lang,industry)=>({
      "event": "industry template selected",
      "properties": {
        "language": lang,
        "industry": industry,
      },
    }),
    COMPELTE_PROCESS_ON_CLICK: (lang)=>({
      "event": "proceeds on click",
      "properties": {
        "language": lang
      },
    }),
    COMPELTE_PROCESS_CLICK_ON_SUCCESS: (lang)=>({
      "event": "application customized Start",
      "properties": {
        "language": lang
      },
    }),
    COMPELTE_ON_SUCCESS: (lang)=>({
      "event": "application customized successfully",
      "properties": {
        "language": lang
      },
    }),
    
    
    
    
}


export {
    mixpanelActions,
    currentYear,
    months,
    quaters,
    statusCode,
    LANGUAGE_TYPE,
    staticPageSlug,
    BASEURL,
    mapLib,
    MyRefreshTokenVar,
    MyAccessTokenVar,
    tablePerPage,
    editorButtons,
    editorButtons1,
    colors,
    AVAILABLE_LANG,
    fileTypes,
    SubDomain
}