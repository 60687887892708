import React from "react";
// import ReactLoading from 'react-loading';
import AppPhoneInput from "../../../Components/AppPhoneInput";
import { useSelector } from "react-redux";
import queryString from 'query-string'
import { useSearchParams } from "react-router-dom";
import AppButton from "../../../Components/AppButton";

export default function Step2({setErrors,errors = {}, plansExtra ={}, plans = {},translation,state,setStep, signUpComplete, setState,isLoading }) {

  const {translations} = useSelector(state => state.language);
  const commonTranslation = translations?.common || {};

  const parsed = queryString.parse(window.location.search);
  const [searchParams, setSearchParams]  = useSearchParams();

  const handleChange = async(key, value)=>{

    const _errors = errors
    if(_errors[key]){
      delete _errors[key]
      setErrors(errors)
    }
    setState((state)=>({
      ...state,
      [key]: value
    }))

  }

  return (
    <>
      <div>
            <AppPhoneInput
              name = {"mobile"}
              value = {state.mobile}
              onChange = {(number,country,e)=>{
                handleChange("mobile",e.target.value);
              }}
              label = {commonTranslation["common.mobile_number"] || "Mobile Number"}
              error = {errors.mobile ? <span className = {"error__text"}>{errors.mobile}</span> : null}
            />

              <div className="signInput">
                <label htmlFor="">{translation["signupPage.company_name"] || "Company Name"}</label>
                <div className="signInput__inner">
                  <input placeholder={translation["signupPage.company_name"] || "Company Name"} type="text" id="company_name" name="company_name" value={state.company_name} onChange = {(e)=>handleChange("company_name",e.target.value)}/>
                </div>
                {errors.company_name ? <span className = {"error__text"}>{errors.company_name}</span> : null}
              </div>
              {
                Object.keys(plans || {}).length > 0?
                <>
                <div className="signInput">
                  <label style={{marginBottom: 0, marginRight: 10}}>{`${commonTranslation["common.subscription_plan"]  || "Subscription Plan"} (${commonTranslation["common.trail_for"] || "Trial for"} ${plansExtra?.trial_period || 0} ${commonTranslation["common.days"] || "days"})`}</label>
                </div>
                <div className="row" style={{alignItems: "normal"}}>
                  {
                    Object.keys(plans || {}).map(planKey=>{
                      const plan = plans[planKey]
                      return(
                        <div class={`plan_card__header ${planKey === parsed?.id? "active": ""}`} onClick = {()=>{
                          setSearchParams({id: planKey})
                        }}>
                          <div class="row">
                            <div class="plan_card_title">{plan.title}</div>
                            <div class="plan_card_number"><strong>{plan.currency}{plan.price}</strong>{commonTranslation["common.user"] || "user"}/{plan.per}</div>
                          </div>
                          {
                            plan?.most_popular?
                            <span class="most-popular-plan-container">
                              <span>🔥</span>
                              <span class="most-popular-plan">{commonTranslation["common.most_popular"]}</span>
                            </span>
                            :<></>
                          }
                        </div>
                      )
                    })
                  }
                </div>
                </>
                :
                <></>

              }
              {
                errors?.plan_id?
                <div className="signInput">
                  {errors.plan_id? <span className = {"error__text"}>{errors.plan_id}</span> : null}
                </div>
                :<></>
              }

              <AppButton
                isLoading = {isLoading}
                title = {commonTranslation["common.get_started"] || "Proceed"}
                onClick = {signUpComplete}
                style = {{width: "100%"}}

              />
          </div>
    </>
  );
}
